import React from 'react';
import PropTypes from 'prop-types';

import { AnalogClock } from './AnalogClock';
import { DigitalClock } from './DigitalClock';

export const Clock = (props) => {
    switch (props.content.clockType) {
        case 'analog':
            return <AnalogClock {...props} />;
        case 'digital':
            return <DigitalClock {...props} />;
        default:
            return <DigitalClock {...props} />;
    }
};

Clock.propTypes = {
    content: PropTypes.object.isRequired,
};
