import React from 'react';

import { AutomatedMode } from '../../../store/containers/AutomatedMode';

import { fetchDisplayMeta, setupInitialSlides } from '../../../store/actions';

export class AutomatedModeBootstrap {
    setup(store) {
        store.dispatch(fetchDisplayMeta());
        store.dispatch(setupInitialSlides());
    }

    getComponent() {
        return <AutomatedMode />;
    }
}
