import React from 'react';
import PropTypes from 'prop-types';

import { SlideWrap } from '../../store/containers/SlideWrap';
import { Slide, DISPLAY_MODE_CONTEXT } from '../blocks/Slide';

export const PreviewMode = (props) => {
    return (
        <SlideWrap>
            <Slide displayMode={DISPLAY_MODE_CONTEXT} {...props} />
        </SlideWrap>
    );
};

PreviewMode.propTypes = {
    slideID: PropTypes.number.isRequired,
    slide: PropTypes.object,
    layout: PropTypes.object,
};
