import { createReducer } from '@reduxjs/toolkit';

import {
    storeSlide,
    updateSlide,
    enqueueSlide,
    swapSlides,
    dequeueNewPreloadSlide,
    dequeueNewDisplaySlide,
    clearQueue,
} from '../actions';

export function getSlideIndexes(state) {
    return [state.displaySchedule[0].active ? 0 : 1, state.displaySchedule[0].active ? 1 : 0];
}

export const slideReducer = createReducer(
    {
        byId: {},
        queue: [],
        displaySchedule: [
            { id: 'a', active: true, slideID: null },
            { id: 'b', active: false, slideID: null },
        ],
    },
    {
        /* Slide Scheduling */
        [enqueueSlide.type]: (state, action) => {
            state.queue.push(action.payload.slideID);
        },
        [clearQueue.type]: (state) => {
            state.queue = [];
        },
        [dequeueNewPreloadSlide.type]: (state) => {
            const [, preloadSlideIndex] = getSlideIndexes(state);

            state.displaySchedule[preloadSlideIndex].slideID = state.queue.shift() ?? null;
        },
        [dequeueNewDisplaySlide.type]: (state) => {
            const [displaySlideIndex] = getSlideIndexes(state);

            state.displaySchedule[displaySlideIndex].slideID = state.queue.shift() ?? null;
        },
        [swapSlides.type]: (state) => {
            const [displaySlideIndex, preloadSlideIndex] = getSlideIndexes(state);

            state.displaySchedule[preloadSlideIndex].active = true;
            state.displaySchedule[displaySlideIndex].active = false;
        },
        /* Slide Storage */
        [storeSlide.type]: (state, action) => {
            state.byId[action.payload.slideID] = action.payload;
        },
        [updateSlide.type]: (state, action) => {
            const { slideID, updates } = action.payload;

            state.byId[slideID] = {
                ...state.byId[slideID],
                ...updates,
            };
        },
    }
);
