import { createAction } from '@reduxjs/toolkit';

/**
 * (Saga-Action)
 * Fetches the ticker content
 */
export const fetchTickerContent = createAction(
    'ticker/fetchTickerContent',
    (currentSlideID) => {
        return {
            payload: {
                currentSlideID,
            },
        };
    }
);

/**
 * (Store-Action)
 * Stores the fetched ticker content.
 */
export const storeTickerContent = createAction('ticker/storeTickerContent', (response) => {
    return {
        payload: response,
    };
});
