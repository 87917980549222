import React from 'react';
import PropTypes from 'prop-types';

import { noop } from 'lodash-es';
import { CSSVariableProvider } from '../../helper/CSSVariableProvider';

import './ChangeArrows.scss';

export const ChangeArrows = ({ locked, onForwards, onBackwards }) => {
    return (
        <CSSVariableProvider
            className={`change-arrows ${locked ? 'change-arrows--locked' : ''}`}
            variables={{
                'fa-primary-color': 'white',
                'fa-secondary-color': 'black',
                'fa-secondary-opacity': '0.7',
            }}
        >
            <i onClick={locked ? noop : onBackwards} className="fad fa-arrow-circle-left" />
            <i onClick={locked ? noop : onForwards} className="fad fa-arrow-circle-right" />
        </CSSVariableProvider>
    );
};

ChangeArrows.propTypes = {
    locked: PropTypes.bool.isRequired,
    onForwards: PropTypes.func.isRequired,
    onBackwards: PropTypes.func.isRequired,
};
