import { useState, useCallback, useLayoutEffect } from 'react';

function normalizeDimensions(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
}

export const useDimensions = () => {
    const [dimensions, setDimensions] = useState(null);
    const [node, setNode] = useState(null);

    const ref = useCallback((domNode) => {
        setNode(domNode);
    }, []);

    useLayoutEffect(() => {
        let handle;

        if (node) {
            handle = window.requestAnimationFrame(() =>
                setDimensions(normalizeDimensions(node))
            );
        }

        return () => {
            window.cancelAnimationFrame(handle);
        };
    }, [node]);

    return [ref, dimensions, node];
};
