import { all, spawn, call } from 'redux-saga/effects';

import { Logger } from '../../../common/Logger';

const LOG = new Logger('Save-Saga-Spawner');

export function safeSpawn(sagas) {
    return all(
        sagas.map((saga) => {
            return spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                    } catch (error) {
                        LOG.critical('Caught top-level saga error', error).capture();
                    }
                }
            });
        })
    );
}
