import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash-es';

import { Colors } from '../../../common/Colors';
import { Logger } from '../../../common/Logger';

import { WeatherCard } from './WeatherCard';

import './Weather.scss';

const LOG = new Logger('Weather');

export const Weather = ({ content }) => {
    const { icons, city, showCity, daysPerRow } = content;

    const colors = Colors.fromSlotContent(content);

    const days = content.weather
        .filter((day) => day != null)
        .map((day) => {
            try {
                return JSON.parse(day);
            } catch {
                LOG.info('Could not parse weather response', day).capture();

                return null;
            }
        })
        .filter((day) => day != null)
        .map((day, index) => (
            <WeatherCard
                key={index}
                isToday={index === 0}
                icon={icons[day.weather[0].icon]}
                day={day}
                colors={colors}
            />
        ));

    return (
        <div className="weather">
            <div data-days-per-row={daysPerRow} className={`weather__cards`}>
                {_.chunk(days, daysPerRow).map((chunk, index) => [
                    ...chunk,
                    <div
                        key={`weather-card-break-${index}`}
                        className="weather-card-break"
                    ></div>,
                ])}
            </div>

            {showCity && city != null && (
                <div
                    className="weather__city"
                    style={{
                        color: colors.font(0),
                        background: colors.background(3),
                        borderColor: colors.background(2),
                    }}
                >
                    <span className="weather__city--prefix">Wetter für </span>
                    {'"'}
                    {city}
                    {'"'}
                </div>
            )}
        </div>
    );
};

Weather.propTypes = {
    content: PropTypes.object.isRequired,
};
