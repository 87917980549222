import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class IFrame extends Component {
    constructor(props) {
        super(props);

        this.refreshIntervalID = null;
        this.iframeRef = React.createRef();
    }

    componentDidMount() {
        this.startRefreshCycle();
    }

    componentDidUpdate() {
        this.clearTimers();
        this.startRefreshCycle();
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    clearTimers = () => {
        clearInterval(this.refreshIntervalID);
        this.refreshIntervalID = null;
    };

    startRefreshCycle = () => {
        if (this.refreshIntervalID != null) {
            return;
        }

        this.refreshIntervalID = setInterval(() => {
            this.refreshIFrame();
        }, this.props.content.refreshRate || 20 * 60 * 1000);
    };

    refreshIFrame = () => {
        const { current: embed } = this.iframeRef;

        if (embed == null) {
            return;
        }

        // This will force the IFrame to reload
        embed.src += '';
    };

    render() {
        const { content } = this.props;

        return (
            <iframe
                ref={this.iframeRef}
                loading="eager"
                sandbox="allow-same-origin allow-top-navigation-by-user-activation allow-scripts"
                width="100%"
                height="100%"
                title={`content-iframe-${content.id}`}
                src={content.url}
            ></iframe>
        );
    }
}

IFrame.propTypes = {
    content: PropTypes.object.isRequired,
};
