import React from 'react';
import PropTypes from 'prop-types';

export const CSSVariableProvider = ({ variables, children, className, style }) => {
    const variableEntries = Object.entries(variables)
        .filter(([, value]) => !!value)
        .map(([key, value]) => [`--${key}`, value]);

    const variableStyles = Object.fromEntries(variableEntries);

    return (
        <div className={className} style={{ ...variableStyles, ...style }}>
            {children}
        </div>
    );
};

CSSVariableProvider.propTypes = {
    variables: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
};
