import { useState } from 'react';
import { useEventListener } from './useEventListener';

const getStatusText = (isOnline) => (isOnline ? 'online' : 'offline');

export const useNetworkStatus = (onChange) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const setNetworkStatus = (updatedStatus) => {
        setIsOnline(updatedStatus);
        onChange(getStatusText(updatedStatus));
    };

    useEventListener(window, 'online', () => setNetworkStatus(true));
    useEventListener(window, 'offline', () => setNetworkStatus(false));

    return getStatusText(isOnline);
};
