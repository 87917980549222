import { noop } from 'lodash-es';
import { useState, useEffect } from 'react';

export const useTimeout = (timeout, once) => {
    const [done, setDone] = useState(true);

    useEffect(() => {
        if (!done && once) {
            return noop;
        }

        const timeoutID = setTimeout(() => {
            setDone(false);
        }, timeout);

        return () => clearTimeout(timeoutID);
    }, [done, once, timeout]);

    return done;
};
