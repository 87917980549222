import { combineReducers } from 'redux';

import { slideReducer } from './slide.reducer';
import { layoutReducer } from './layout.reducer';
import { tickerReducer } from './ticker.reducer';
import { changesReducer } from './changes.reducer';
import { contentReducer } from './content.reducer';
import { displayReducer } from './display.reducer';
import { resourceReducer } from './resource.reducer';

export const rootReducer = combineReducers({
    slides: slideReducer,
    ticker: tickerReducer,
    layouts: layoutReducer,
    display: displayReducer,
    changes: changesReducer,
    contents: contentReducer,
    resources: resourceReducer,
});
