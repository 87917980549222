import { createReducer } from '@reduxjs/toolkit';

import { storeTickerContent } from '../actions';

export const tickerReducer = createReducer(
    {
        tickerState: {},
    },
    {
        [storeTickerContent.type]: (state, action) => {
            state.tickerState = action.payload;
        },
    }
);
