import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { LogLevels } from './Logger';

const CONFIG = {
    development: {
        BACKEND_API_BASE_URL: 'http://welcome.gh/api/v1/frontend',
    },
    approval: {
        BACKEND_API_BASE_URL: 'https://approval.welcome-ds.de/api/v1/frontend',
    },
    production: {
        BACKEND_API_BASE_URL: 'https://www.welcome-ds.de/api/v1/frontend',
    },
    demo: {
        BACKEND_API_BASE_URL: 'https://demo.welcome-ds.de/api/v1/frontend',
    },
};

export class Configuration {
    static env = CONFIG[Configuration.getAppEnv()];

    static QUERY_PARAMS = new URLSearchParams(window.location.search);

    static initialize() {
        let sentryEnabled = false;

        if (Configuration.isProductionBuild()) {
            Sentry.init({
                dsn: 'https://8ab4f68972e34553ab2ea0bcb3abaf17@sentry.digital-castle.de//4',
                integrations: [new Integrations.BrowserTracing()],
                maxBreadcrumbs: 200,
                tracesSampleRate: 1,
                sampleRate: 1,
            });

            sentryEnabled = true;
        }

        const parts = [
            '%c',
            'Welcome DS!',
            ` | Environment: ${Configuration.getAppEnv()}`,
            ` | Version: ${Configuration.getVersionIdentifier()}`,
            sentryEnabled ? ' | Sentry:Enabled' : null,
        ].filter((part) => !!part);

        console.log(parts.join(''), 'background: lightgreen;');
    }

    static getVersionIdentifier() {
        return process.env.APP_VERSION;
    }

    static isProductionBuild() {
        return process.env.IS_PRODUCTION;
    }

    static getAppEnv() {
        return process.env.APP_ENV;
    }

    static getApiBaseUrl() {
        return Configuration.env.BACKEND_API_BASE_URL;
    }

    static getUrlLogLevel() {
        const queryParam = Configuration.QUERY_PARAMS.get('loglevel');

        if (queryParam == null) {
            return null;
        }

        return LogLevels.fromString(queryParam);
    }

    static isDebugMode() {
        return Configuration.QUERY_PARAMS.has('debug');
    }
}
