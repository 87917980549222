import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import { useTimeout, useNetworkStatus, useEventListener } from '../../../hooks';

import { ChangeArrows } from '../../overlay/ChangeArrows/ChangeArrows';
import { SlidePreview } from '../../overlay/Slider/SlidePreview';
import { LogoDisplay } from './LogoDisplay';
import { NewsTicker } from '../../overlay/NewsTicker/NewsTicker';
import { IconList } from '../../overlay/Icons/IconList';

import { Configuration } from '../../../common/Configuration';
import { Logger } from '../../../common/Logger';

import './SlideWrap.scss';

const LOG = new Logger('SlideWrap');

export const SlideWrap = ({
    children,
    ticker,
    display,
    isPreview,
    interactionsLocked,
    setSlideById,
    switchToNextSlide,
    switchToPreviousSlide,
}) => {
    const showLogo = useTimeout(Configuration.isDebugMode() ? 500 : 3000, true);

    const networkStatus = useNetworkStatus((status) => {
        LOG.info('Network-Status changed to:', status);
    });

    const handleOnKeyDown = useCallback(
        ({ code }) => {
            if (isPreview || interactionsLocked) {
                return;
            }

            switch (code) {
                case 'ArrowLeft':
                    switchToPreviousSlide();
                    break;
                case 'ArrowRight':
                    switchToNextSlide();
                    break;
                default:
                    break;
            }
        },
        [switchToNextSlide, switchToPreviousSlide, isPreview, interactionsLocked]
    );

    useEventListener(window, 'keydown', handleOnKeyDown, 3000);

    const icons = [
        showLogo ? 'far fa-spinner' : null,
        networkStatus === 'offline' ? 'far fa-wifi-slash' : null,
    ].filter((icon) => !!icon);

    const enableInteractive = display.schedule && !isPreview;

    const shouldDisplayThumbnails =
        enableInteractive && (display.touch === 'both' || display.touch === 'thumbnails');

    const shouldDisplayArrows =
        enableInteractive && (display.touch === 'both' || display.touch === 'arrows');

    return (
        <>
            {/* Logo */}
            <CSSTransition timeout={1000} in={showLogo} classNames="logo--transition">
                <LogoDisplay />
            </CSSTransition>
            {/* Icons */}
            <div className="display-icons">
                <IconList icons={icons} blink />
            </div>
            {/* Content */}
            <div className="content-container full-size">
                <div className="slide-container full-size">
                    {ticker && ticker.enabled && <NewsTicker {...ticker} />}
                    <div className="slides">
                        {shouldDisplayArrows && (
                            <ChangeArrows
                                locked={interactionsLocked}
                                onForwards={switchToNextSlide}
                                onBackwards={switchToPreviousSlide}
                            />
                        )}
                        {children}
                    </div>
                </div>
                {/* Interactive element */}
                {shouldDisplayThumbnails && (
                    <SlidePreview
                        colors={ticker?.colors}
                        locked={interactionsLocked}
                        onSwitch={setSlideById}
                        slides={display.schedule}
                    />
                )}
            </div>
        </>
    );
};

SlideWrap.propTypes = {
    // Display states
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    display: PropTypes.object,
    ticker: PropTypes.object,
    // Flags
    interactionsLocked: PropTypes.bool,
    isPreview: PropTypes.bool,
    // Handlers
    setSlideById: PropTypes.func,
    switchToNextSlide: PropTypes.func,
    switchToPreviousSlide: PropTypes.func,
};
