import React, { Component } from 'react';
import PropTypes from 'prop-types';

import videojs from 'video.js';
import 'videojs-plugin-audio-switch';

import './VideoJSPlayer.scss';
import 'video.js/dist/video-js.min.css';

export class VideoJSPlayer extends Component {
    constructor(props) {
        super(props);

        this.player = null;
        this.videoElement = React.createRef();
    }

    componentDidMount() {
        this.updatePlayerState();
    }

    componentDidUpdate() {
        this.updatePlayerState();
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
            this.player = null;
        }
    }

    updatePlayerState() {
        // Since no props can be changed dynamically other than
        // "play" this function only respects the play prop.
        // If in the future the video need to be able to be muted/umuted
        // this would be the place to dynamically update the player

        if (!this.player) {
            this.createPlayer();
        }

        if (this.props.play) {
            this.player.currentTime(0);
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    createPlayer() {
        const { muted, videoSource, audioSource } = this.props;

        const options = {
            muted,
            loop: true,
            fluid: true,
            preload: 'auto',
            autoplay: false,
            controls: false,
            sources: [
                {
                    src: videoSource,
                    type: 'video/mp4',
                },
            ],
            plugins: {},
        };

        if (audioSource && !muted) {
            const track = {
                kind: 'main',
                enabled: true,
                url: audioSource,
            };

            options.plugins['audio-switch'] = {
                audioTracks: [track],
                syncInterval: 1000,
            };
        }

        this.player = videojs(this.videoElement.current, options);
    }

    render() {
        // The div wrapper needs to be there! It avoids an issue when unloading the component!
        // Since videojs.dispose will remove the element from the real dom, react thinks the element
        // is still there, when it tries to remove it when the component is unmounted an error occures.
        // since the vdom and the real dom got out of sync.
        return (
            <div className="full-size">
                <video ref={this.videoElement} className="video-js-player">
                    <track kind="captions"></track>
                </video>
            </div>
        );
    }
}

VideoJSPlayer.propTypes = {
    play: PropTypes.bool.isRequired,
    muted: PropTypes.bool.isRequired,
    videoSource: PropTypes.string.isRequired,
    audioSource: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
