import React from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';

import './WeatherCard.scss';

function formatTemperature(num) {
    return `${Math.round(num)} °C `;
}

export const WeatherCard = ({ day, icon, colors, isToday }) => {
    return (
        <div
            className="weather-card"
            style={{
                color: colors.font(0),
                borderColor: isToday ? colors.font(1) : colors.background(3),
                background: colors.background(3),
            }}
        >
            <div className="weather-card__header">
                <div className="weather-card__date">
                    <span>
                        {DateTime.fromMillis(day.dt * 1000)
                            .setLocale('de')
                            .toFormat('EEEE - dd. MMMM')}
                    </span>
                </div>
            </div>

            <div className="weather-card__body">
                <div className="weather-card__temerature" style={{ color: colors.font(2) }}>
                    <div className="weather-card__temperature--current">
                        {formatTemperature(day.temp.day)}
                    </div>
                    <div className="weather-card__temperature--range">
                        {formatTemperature(day.temp.min)}
                        &nbsp;–&nbsp;
                        {formatTemperature(day.temp.max)}
                    </div>
                </div>

                <div className="weather-card__description">
                    <i
                        style={{
                            '--fa-primary-color': colors.font(3, '#111111'),
                            '--fa-secondary-color': colors.font(4, '#fdcb00'),
                        }}
                        className={icon.code}
                    />
                    <small>{day.weather[0].description}</small>
                </div>
            </div>
        </div>
    );
};

WeatherCard.propTypes = {
    isToday: PropTypes.bool.isRequired,
    day: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
};
