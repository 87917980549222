import { createReducer } from '@reduxjs/toolkit';

import { storeContent, removeContent } from '../actions';

export const contentReducer = createReducer(
    {
        bySlideId: {},
    },
    {
        [storeContent.type]: (state, action) => {
            const { slideID, tileID } = action.payload;

            if (state.bySlideId[slideID] == null) {
                state.bySlideId[slideID] = {
                    byTileId: {},
                };
            }

            state.bySlideId[slideID].byTileId[tileID] = action.payload;
        },
        [removeContent.type]: (state, action) => {
            const { slideID, tileID } = action.payload;

            if (state.bySlideId[slideID] == null) {
                return;
            }

            delete state.bySlideId[slideID].byTileId[tileID];
        },
    }
);
