import React from 'react';
import PropTypes from 'prop-types';

import './IconList.scss';

export const IconList = ({ icons, blink }) => {
    return (
        <div className={`icon-list ${blink ? 'icon-list--blink' : ''}`}>
            {icons.map((icon, index) => (
                <div key={index} className="icon-list__wrapper">
                    <i className={`icon-list__icon ${icon}`}></i>
                </div>
            ))}
        </div>
    );
};

IconList.propTypes = {
    icons: PropTypes.array,
    blink: PropTypes.bool,
};
