import { useState, useEffect } from 'react';

import { DateTime } from 'luxon';

function getCurrentTime() {
    return DateTime.fromMillis(Date.now());
}

function toDoubleDigit(input) {
    return input.toString().padStart(2, '0');
}

export const useCurrentTime = () => {
    const [time, setTime] = useState(getCurrentTime());

    useEffect(() => {
        const intervalID = setInterval(() => {
            setTime(getCurrentTime());
        }, 1000);

        return () => clearInterval(intervalID);
    });

    return {
        time,
        hour: toDoubleDigit(time.hour),
        minute: toDoubleDigit(time.minute),
        second: toDoubleDigit(time.second),
    };
};
