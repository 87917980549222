import { put, takeEvery } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';
import { fetchImage, storeResource } from '../actions';

function* fetchImageSaga(action) {
    const { loader, cacheKey } = action.payload;

    const response = yield retryRequest(() => API.fetchImageMetadata(loader.imageUrl), 20);

    yield put(storeResource(response, cacheKey));
}

function* watchResourceActions() {
    yield takeEvery(fetchImage.type, fetchImageSaga);
}

export const ResourceSagas = [watchResourceActions];
