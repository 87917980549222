const selectContents = (state) => state.contents;

export const selectTileContentByID = (slideID, tileID, state) => {
    const contents = selectContents(state);

    const bySlide = contents.bySlideId[slideID];

    if (bySlide == null) {
        return null;
    }

    const byTileId = bySlide.byTileId[tileID];

    if (byTileId == null) {
        return null;
    }

    return byTileId;
};
