import { createReducer } from '@reduxjs/toolkit';

import {
    storeTickerContent,
    setLastTickerUpdateTime,
    setLastSlideUpdateTime,
    swapSlides,
} from '../actions';

import { unixTimestamp } from '../../common/Time';

export const changesReducer = createReducer(
    {
        slide: {
            initialRenderTime: null,
            lastUpdateTime: null,
        },
        ticker: {
            initialRenderTime: null,
            lastUpdateTime: null,
        },
    },
    {
        [swapSlides.type]: (state) => {
            state.slide.initialRenderTime = unixTimestamp();
            state.slide.lastUpdateTime = unixTimestamp();
        },
        [setLastSlideUpdateTime.type]: (state) => {
            state.slide.lastUpdateTime = unixTimestamp();
        },
        [storeTickerContent.type]: (state) => {
            state.ticker.initialRenderTime = unixTimestamp();
            state.ticker.lastUpdateTime = unixTimestamp();
        },
        [setLastTickerUpdateTime.type]: (state) => {
            state.ticker.lastUpdateTime = unixTimestamp();
        },
    }
);
