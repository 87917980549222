import React from 'react';
import PropTypes from 'prop-types';

import ReactClock from 'react-clock';

import { Colors } from '../../../common/Colors';
import { StyleRules } from '../../helper/StyleRules';
import { useCurrentTime, useElementUUID } from '../../../hooks';

import './AnalogClock.scss';
import 'react-clock/dist/Clock.css';

export function createClockConfig(size) {
    const sizes = {
        hourHandLength: 50,
        hourHandOppositeLength: 15,
        hourHandWidth: 15,
        hourMarksLength: 15,
        hourMarksWidth: 9,
        minuteHandLength: 70,
        minuteHandOppositeLength: 15,
        minuteHandWidth: 15,
        minuteMarksLength: 7.5,
        minuteMarksWidth: 5,
        secondHandLength: 75,
        secondHandOppositeLength: 15,
        secondHandWidth: 4,
    };

    if (size < 400) {
        sizes.hourHandWidth = 10;
        sizes.minuteHandWidth = 10;
        sizes.hourMarksWidth = 4;
        sizes.minuteMarksWidth = 2;
    }

    return sizes;
}

export const AnalogClock = ({ content, dimensions }) => {
    const { time } = useCurrentTime();
    const rootID = useElementUUID('analog-clock');

    const colors = Colors.fromSlotContent(content);
    const size = Math.min(dimensions?.height || 100, dimensions?.width || 100) - 70;

    const styles = `
        #${rootID} .react-clock__hour-hand__body {
            background-color: ${colors.font(0, '#000000')};
        }

        #${rootID} .react-clock__minute-hand__body {
            background-color: ${colors.font(1, '#000000')};
        }

        #${rootID} .react-clock__second-hand__body {
            background-color: ${colors.font(2, '#005a93')};
        }

        #${rootID} .react-clock {
            background-color: ${colors.background(1, '#ffffff')};
            border-color: ${colors.background(2, '#000000')};
        }

        #${rootID} .react-clock__face {
            border-color: ${colors.background(1, '#ffffff')};
        }

        #${rootID} .react-clock__minute-mark__body {
            background-color: ${colors.background(3, '#666666')};
        }

        #${rootID} .react-clock__hour-mark__body {
            background-color: ${colors.background(4, '#000000')};
        }
    `;

    return (
        <div id={rootID} className="analog-clock full-size center">
            <StyleRules condition={true} content={styles} />
            <span
                className="analog-clock__center-dot"
                style={{ backgroundColor: colors.font(3, '#005a93') }}
            ></span>
            <ReactClock size={size} value={time.toJSDate()} {...createClockConfig(size)} />
        </div>
    );
};

AnalogClock.propTypes = {
    content: PropTypes.object.isRequired,
    dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
};
