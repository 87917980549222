export class CSSHelp {
    static multiclass(...classes) {
        return classes
            .map((className) => typeof className.trim === 'function' && className.trim())
            .filter((className) => !!className)
            .join(' ');
    }

    static conditionalStyles(...definitions) {
        return definitions
            .filter((definition) => definition != null)
            .reduce((definition, styles) => Object.assign(styles, definition));
    }
}
