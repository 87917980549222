import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../../../common/Colors';
import { FitText } from '../../helper/FitText';
import { useCurrentTime } from '../../../hooks';

import './DigitalClock.scss';

export const DigitalClock = ({ content }) => {
    const colors = Colors.fromSlotContent(content);
    const { hour, minute, second } = useCurrentTime();

    return (
        <div className="digital-clock full-size center">
            <FitText className="digital-clock__text">
                <span className="digital-clock__hours" style={colors.fontStyle(0)}>
                    {hour}
                </span>

                <span className="digital-clock__points" style={colors.fontStyle(3)}>
                    :
                </span>

                <span className="digital-clock__minutes" style={colors.fontStyle(1)}>
                    {minute}
                </span>

                <span className="digital-clock__points" style={colors.fontStyle(3)}>
                    :
                </span>

                <span className="digital-clock__seconds" style={colors.fontStyle(2)}>
                    {second}
                </span>
            </FitText>
        </div>
    );
};

DigitalClock.propTypes = {
    content: PropTypes.object.isRequired,
};
