import React from 'react';

import Logo from '../../../assets/images/welcome-logo.png';

import './LogoDisplay.scss';

export const LogoDisplay = () => {
    return (
        <div className="logo full-size center">
            <img className="logo__image" src={Logo} alt="logo" />
            <hr />
            <div>
                <h3 className="logo__subtitle">Welcome! Digital Signage</h3>
            </div>
        </div>
    );
};
