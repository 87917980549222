import React from 'react';
import PropTypes from 'prop-types';

import './Column.scss';

export const Column = (props) => {
    return (
        <div className="column" style={{ width: `${props.width}%` }}>
            {props.children}
        </div>
    );
};

Column.propTypes = {
    // The width of the Column in percent, relative to the parent.
    width: PropTypes.number.isRequired,
    // The children to render in this column
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
