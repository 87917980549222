import React from 'react';
import PropTypes from 'prop-types';

import { CSSHelp } from '../../../common/CSSHelp';

import './NewsTickerElement.scss';

export const NewsTickerElement = ({ bold, content }) => {
    return (
        <>
            <span
                className={CSSHelp.multiclass(
                    'ticker__element',
                    `ticker__element${bold ? '--bold' : ''}`
                )}
            >
                {content}
            </span>
            <span className="ticker__element__spacer">+++</span>
        </>
    );
};

NewsTickerElement.propTypes = {
    bold: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
};
