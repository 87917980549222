// import "./common/debug/why-did-you-render-addon";

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { App } from './components/App';
import { ErrorDisplay } from './components/helper/ErrorDisplay';

import { Configuration } from './common/Configuration';

Configuration.initialize();

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<ErrorDisplay />}>
        <App />
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
);
