import { API } from '../../../common/API';
import { Configuration } from '../../../common/Configuration';
import { Logger, LogLevels } from '../../../common/Logger';

import { PreviewModeBootstrap } from './PreviewModeBootstrap';
import { AutomatedModeBootstrap } from './AutomatedModeBootstrap';

import { fetchTickerContent, startChangesFetch } from '../../../store/actions';

export class DisplayBootstrap {
    /**
     * Detect and setup the best matching display mode.
     *
     * @param {any} store The store
     * @returns {any} The element to render
     */
    static setupDisplayMode(store) {
        DisplayBootstrap.baseSetup(store);

        let mode;
        if (API.isPreviewMode()) {
            mode = new PreviewModeBootstrap(
                parseInt(Configuration.QUERY_PARAMS.get('preview'))
            );
        } else {
            mode = new AutomatedModeBootstrap();
        }

        mode.setup(store);

        return mode.getComponent();
    }

    static findLogLevel() {
        if (Configuration.getUrlLogLevel() != null) {
            return Configuration.getUrlLogLevel();
        }

        return LogLevels.TRACE;
    }

    /**
     * Setup function to prepare everything
     * that needs to be setup, regardless of mode.
     *
     * @private
     * @param {{ dispatch: function(object): void}} store
     */
    static baseSetup(store) {
        Logger.setLogLevel(this.findLogLevel());

        API.parseUrlTokens();

        store.dispatch(fetchTickerContent());
        store.dispatch(startChangesFetch());
    }
}
