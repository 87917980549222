import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../../../common/Colors';
import { FitText } from '../../helper/FitText';

import './Signpost.scss';

export const Signpost = ({ content }) => {
    const colors = Colors.fromSlotContent(content);

    return (
        <div className="signpost full-size center">
            <FitText>
                <i
                    className="far fa-long-arrow-up signpost__needle"
                    style={{
                        color: colors.font(0, 'black'),
                        transform: `rotate(${content.pointerAngle}deg)`,
                        transformOrigin: 'center',
                    }}
                />
            </FitText>
            <FitText>
                <p
                    style={{ color: colors.font(1, 'black') }}
                    className="signpost__description"
                    dangerouslySetInnerHTML={{ __html: content.description }}
                ></p>
            </FitText>
        </div>
    );
};

Signpost.propTypes = {
    content: PropTypes.object.isRequired,
};
