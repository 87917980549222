import React from 'react';

import { Provider } from 'react-redux';
import { DisplayBootstrap } from './modes/bootstrap/DisplayBootstrap';

import { store } from '../store/index';

import '../assets/styles/styles.scss';

export const App = () => {
    const displayMode = DisplayBootstrap.setupDisplayMode(store);

    return <Provider store={store}>{displayMode}</Provider>;
};
