export class Colors {
    /**
     * Constructs a new Colors instance.
     *
     * @param {Array} fonts The font colors
     * @param {Array} backgrounds The background colors
     */
    constructor(fonts, backgrounds) {
        this.fonts = fonts;
        this.backgrounds = backgrounds;
    }

    static hexToRGBA(hexColor, transparency) {
        if (hexColor == null) {
            return {
                red: 255,
                green: 255,
                blue: 255,
                alpha: 1,
                get css() {
                    return 'rgba(255,255,255,1)';
                },
            };
        }

        const hexint = parseInt(hexColor.split('#').join(''), 16);

        return {
            red: (hexint >> 16) & 255,
            green: (hexint >> 8) & 255,
            blue: hexint & 255,
            alpha: transparency,
            get css() {
                return `rgba(${this.red},${this.green},${this.blue},${this.alpha})`;
            },
        };
    }

    /**
     * Creates a Colors object from the content of a Slot, HTTP Call
     *
     * @static
     * @param {any} content The content json of the slot
     * @returns {Colors} The colors object
     */
    static fromSlotContent(content) {
        const { fonts, backgrounds } = content.colors;

        return new Colors(fonts, backgrounds);
    }

    /**
     * Gets the font color for the specified index
     *
     * @param {number} index The index of the color
     * @param {any} fallback The value to return if there is no value at index
     * @returns {string} The font colors for the given index
     */
    font(index, fallback = null) {
        return Colors._getSafeColor(this.fonts, index, fallback);
    }

    /**
     * Generates a object that can be used directly to insert into a styles rule.
     *
     * @param {number} index The index of the color
     * @param {any} fallback The value to use if there is no value at the index
     * @returns {{ color: string }} The font for the given index in react-object format.
     */
    fontStyle(index, fallback = null) {
        return {
            color: this.font(index, fallback),
        };
    }

    /**
     * Gets the background color for the specified index
     *
     * @param {number} index The index of the color
     * @param {any} fallback The value to return if there is no value at index
     * @returns {string} The background for the given index
     */
    background(index, fallback = null) {
        return Colors._getSafeColor(this.backgrounds, index, fallback);
    }

    /**
     * Generates a object that can be used directly to insert into a styles rule.
     *
     * @param {number} index The index of the color
     * @param {any} fallback The value to use if there is no value at the index
     * @returns {{ backgroundColor: string }} The background for the given index in react-style object format
     */
    backgroundStyle(index, fallback = null) {
        return {
            backgroundColor: this.background(index, fallback),
        };
    }

    /**
     * Checks if the specified color is in the object, if it is, it returns the color
     * if the key is missing or null it return the css value 'unset'
     *
     * @private
     * @param {object} target The object of the Color key
     * @param {number} key The Color key
     * @param {any} fallback A fallback value incase there is none set
     * @returns {string} The resolved color or 'inherit' for un-found indexes
     */
    static _getSafeColor(target, key, fallback) {
        if (target == null || typeof target[key] === 'undefined' || target[key] == null) {
            if (fallback == null) {
                return 'inherit';
            }

            return fallback;
        }

        return target[key];
    }
}
