import { createReducer } from '@reduxjs/toolkit';

import { lockInteractions, unlockInteractions, storeDisplayMeta } from '../actions';

export const displayReducer = createReducer(
    {
        meta: {},
        interactionsLocked: false,
    },
    {
        [storeDisplayMeta.type]: (state, action) => {
            state.meta = action.payload.meta;
        },
        [lockInteractions.type]: (state) => {
            state.interactionsLocked = true;
        },
        [unlockInteractions.type]: (state) => {
            state.interactionsLocked = false;
        },
    }
);
