import * as spring from 'react-spring';
import * as easings from 'd3-ease';

export const SWITCH_ANIMATION_DURATION = 1500;

const config = {
    default: {
        ...spring.config.molasses,
        duration: SWITCH_ANIMATION_DURATION,
        easing: easings.easeCubicInOut,
    },
};

export const EMPTY_TRANSITION = {
    from: {},
    enter: {},
    leave: {},
    config: {},
};

export const FADE_TRANSITION = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
};

export const SCALE_UP_TRANSITION = {
    from: { transform: 'scale(0.85)' },
    enter: { transform: 'scale(1)' },
    leave: { transform: 'scale(0.5)' },
    config: config.default,
};

export const SCALE_DOWN_TRANSITION = {
    from: { transform: 'scale(1.25)' },
    enter: { transform: 'scale(1)' },
    leave: { transform: 'scale(0.85)' },
    config: config.default,
};

export const FLY_IN_LEFT_TRANSITION = {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(100%)' },
    config: config.default,
};

export const FLY_IN_RIGHT_TRANSITION = {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(-100%)' },
    config: config.default,
};

export const FLY_IN_BOTTOM_TRANSITION = {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0)' },
    leave: { transform: 'translateY(-100%)' },
    config: config.default,
};

export const FLY_IN_TOP_TRANSITION = {
    from: { transform: 'translateY(-100%)' },
    enter: { transform: 'translateY(0)' },
    leave: { transform: 'translateY(100%)' },
    config: config.default,
};

export function resolveAnimationFromName(name) {
    const map = {
        none: EMPTY_TRANSITION,
        fade: FADE_TRANSITION,
        scaleup: SCALE_UP_TRANSITION,
        scaledown: SCALE_DOWN_TRANSITION,
        slideinleft: FLY_IN_LEFT_TRANSITION,
        slideinright: FLY_IN_RIGHT_TRANSITION,
        slideinup: FLY_IN_BOTTOM_TRANSITION,
        slideindown: FLY_IN_TOP_TRANSITION,
    };

    if (name in map) {
        return map[name];
    }

    return EMPTY_TRANSITION;
}
