import { Configuration } from './Configuration';
import { FetchError } from './errors/FetchError';
import { Logger } from './Logger';

const LOG = new Logger('API');

export class API {
    static BASE_URL = Configuration.getApiBaseUrl();

    static URL_SEGMENTS = {
        NEXT_SLIDE: 'nextSlide',
        PREVIOUS_SLIDE: 'previousSlide',
        SLIDE_BY_ID: 'setSlide',
        SLIDE_LAYOUT: 'slidelayout',
        TILE_CONTENT: 'tileContent',
        TICKER_CONTENT: 'ticker',
        CHANGES: 'changes',
        TICKER_CHANGES: 'tickerChanges',
        DISPLAY_META: 'displayMeta',
    };

    static defaultBodyInclude = {
        displayToken: null,
        watrfri: null,
    };

    /**
     * Checks if the app is currently running in preview mode
     *
     * @returns {boolean} If currently in preview mode
     */
    static isPreviewMode() {
        return Configuration.QUERY_PARAMS.has('preview');
    }

    /**
     * Sets the Displaytoken
     *
     * @returns {void}
     */
    static findDisplayToken() {
        API.defaultBodyInclude.displayToken = Configuration.QUERY_PARAMS.get('displayToken');
    }

    /**
     * Sets the "watrfri" token
     *
     * @returns {void}
     */
    static findWatrfriToken() {
        const token = Configuration.QUERY_PARAMS.get('watrfri');

        if (token == null) {
            LOG.warn(
                'Could not find "watrfri" token in URL! This token should always be set even in dev environments!'
            );
        } else {
            API.defaultBodyInclude.watrfri = token;
        }
    }

    /**
     * Finds or requests all tokens
     *
     * @returns {void}
     */
    static parseUrlTokens() {
        API.findDisplayToken();
        API.findWatrfriToken();
    }

    /**
     * Checks the response for some common error states
     *
     * @private
     * @param {Response} response The response to check
     * @param {any} requestOptions The asd
     * @returns {Promise<any>}
     */
    static async evaluateResponse(response, requestOptions) {
        const content = !response.ok ? await response.text() : await response.json();

        if (!response.ok) {
            return new FetchError(
                `(API) Response not ok - Failed to fetch "${requestOptions.url}" - Content: ${content}`,
                requestOptions,
                response,
                this.URL_SEGMENTS
            );
        }

        if (typeof content.error !== 'undefined') {
            return new FetchError(
                `(API) Backend responded with an error - Failed to fetch "${requestOptions.url}" with params: ${requestOptions.body}`,
                requestOptions,
                response,
                this.URL_SEGMENTS
            );
        }

        return content;
    }

    /**
     * Generic method to make a request to the backend.
     *
     * @private
     * @param {string} endpoint The enpoint to post to
     * @param {object} body The body to send
     * @param {boolean} prefix Should the url be prefixed with the base_url?
     * @param {string} httpMethod Which http method to use
     * @returns {Promise<any>} The fetched json
     */
    static async fetchJson(endpoint, body, prefix = true, httpMethod = 'POST') {
        const url = prefix ? `${this.BASE_URL}/${endpoint}` : endpoint;

        const requestOptions = {
            url,
            method: httpMethod,
            credentials: 'omit',
            body: JSON.stringify({
                ...this.defaultBodyInclude,
                ...body,
            }),
        };

        try {
            const response = await fetch(url, requestOptions);
            const result = await this.evaluateResponse(response, requestOptions);

            if (result instanceof Error) {
                LOG.error(
                    `Request to: "${endpoint}" with body`,
                    body,
                    'failed with error',
                    result.message
                );

                throw result;
            } else {
                LOG.verbose(`Request to "${endpoint}" resolved to`, result);
            }

            return result;
        } catch (error) {
            LOG.error(
                `Request to "${endpoint}"`,
                'with body',
                JSON.stringify(body),
                'failed due to a network error',
                error.message
            ).capture();

            throw error;
        }
    }

    /**
     * Fetches the metadata of the next slide.
     *
     * @param {number} activeSlideID The ID of the currently active slide, null if none
     * @param {boolean} isPreload Checks if the next slide is used only for preload
     * @returns {Promise<{ slideID: number, duration: number }>} The next slide
     */
    static fetchNextSlide(activeSlideID, isPreload = false) {
        return API.fetchJson(API.URL_SEGMENTS.NEXT_SLIDE, {
            activeSlideID,
            preload: isPreload,
        });
    }

    /**
     * Fetches the previous slide
     *
     * @returns {Promise<{ slideID: number, duration: number }>} The previous slide
     */
    static fetchPreviousSlide() {
        return API.fetchJson(API.URL_SEGMENTS.PREVIOUS_SLIDE, {});
    }

    /**
     * Fetches a slide by id. This also marks the slide
     * with the given ID as active.
     *
     * @param {number} id The id of the slide
     * @returns {Promise<object>}
     */
    static fetchSlideById(id) {
        return API.fetchJson(API.URL_SEGMENTS.SLIDE_BY_ID, {
            slideID: id,
        });
    }

    /**
     * Fetches the layout for a given slide
     *
     * @param {number} slideID The slide ID for which to fetch the layout
     * @returns {Promise<{ meta: object, layout: object }>} The slide layout
     */
    static fetchSlideLayout(slideID) {
        return API.fetchJson(API.URL_SEGMENTS.SLIDE_LAYOUT, {
            slideID,
            slideWidth: window.innerWidth,
            slideHeight: window.innerHeight,
        });
    }

    /**
     * Fetches the content of a Tile
     *
     * @param {number} slideID The ID of the slide the tile belongs to
     * @param {number} tileID The ID of the tile
     * @param {number} displayWidth The width of the tile on the screen
     * @param {number} displayHeight The height of the tile on the screen
     * @returns {Promise<object>} The tile content
     */
    static fetchTileContent(slideID, tileID, displayWidth, displayHeight) {
        return API.fetchJson(API.URL_SEGMENTS.TILE_CONTENT, {
            slideID,
            tileID,
            width: displayWidth,
            height: displayHeight,
        });
    }

    /**
     * Fetches the ticker content
     *
     * @returns {Promise<object>} The ticker content
     */
    static fetchTickerContent() {
        return API.fetchJson(API.URL_SEGMENTS.TICKER_CONTENT, {});
    }

    /**
     * Fetches image metadata from a url
     *
     * @param {string} url The url of the image
     * @returns {Promise<{ url: string, width: number, height: number }>} The resolved image
     */
    static fetchImageMetadata(url) {
        return API.fetchJson(url, {}, false);
    }

    /**
     * Fetches the changes that have been made for the given slide.
     *
     * @param {number} slideID The ID of the slide
     * @param {number} renderedAt When the slide was first rendered (UNIX Timestamp)
     * @param {number} lastChangeAt When the last change was processed (UNIX Timestamp)
     * @param {number} layoutID The ID of the layout
     * @param {number} duration How long the slide is supposed to be display in total (Milliseconds)
     * @param {object} tiles The content of the layout in the following format: { "tile<TILE_ID>": <CONTENT_ID> }
     * @param {number} backgroundImageID The ID of the background image
     * @param {number} backgroundColor hex code for background color
     * @param {object} borderAround The current "borderAround" state
     * @param {boolean} isPreviewMode indicating wether the the current slide is in preview mode or not
     *
     * @returns {Promise<{
            next: boolean,
            newLayoutID: boolean | number,
            newRemainingDuration: boolean | number,
            changedTiles: boolean | object,
            newDuration: number,
            reload: boolean,
            newBorderAround: boolean | string,
            newBackgroundImageID: boolean | number,
            newBackgroundImageURL: boolean | string,
            newBackgroundColor: boolean | string,
        }>} The slide changes
     */
    static fetchSlideChanges(
        slideID,
        renderedAt,
        lastChangeAt,
        layoutID,
        duration,
        tiles,
        backgroundImageID,
        backgroundColor,
        borderAround,
        isPreviewMode
    ) {
        return API.fetchJson(API.URL_SEGMENTS.CHANGES, {
            slideID,
            renderedAt,
            lastChangeAt,
            layoutID,
            duration,
            tiles,
            backgroundImageID,
            backgroundColor,
            borderAround,
            preview: isPreviewMode,
            slideWidth: window.innerWidth,
            slideHeight: window.innerHeight,
        });
    }

    /**
     * Fetches the Ticker changes
     *
     * @param {number} renderedAt When the ticker was first rendered
     * @param {number} lastChangeAt When the ticker was last changed
     * @param {object} tickerState The current ticker state
     * @param {number} slideID The current slide ID
     * @returns {Promise<any>} The ticker changes
     */
    static fetchTickerChanges(renderedAt, lastChangeAt, tickerState, slideID) {
        return API.fetchJson(API.URL_SEGMENTS.TICKER_CHANGES, {
            slideID,
            renderedAt,
            lastChangeAt,
            ticker: {
                ...tickerState,
                entries: tickerState.entries.map((x) => x.id),
            },
        });
    }

    /**
     * Fetches metadata about the current display
     *
     * This data includes the touch features that should be enabled
     * and the slide schedule
     *
     * @returns {Promise<object>}
     */
    static fetchDisplayMeta() {
        return API.fetchJson(API.URL_SEGMENTS.DISPLAY_META, null);
    }
}
