import { batch, connect } from 'react-redux';

import { SlideWrap as SlideWrapComponent } from '../../components/modes/common/SlideWrap';

import { cancelScheduledSlideSwap, updateDisplaySlide } from '../actions';

import {
    selectDisplayInteractionsLock,
    selectDisplayMeta,
    selectTickerState,
} from '../selectors';

function createDisplaySlideSwitchAction(dispatch) {
    return (direction) => {
        batch(() => {
            dispatch(cancelScheduledSlideSwap());
            dispatch(updateDisplaySlide(direction));
        });
    };
}

const mapStateToProps = (state) => {
    return {
        ticker: selectTickerState(state),
        display: selectDisplayMeta(state),
        interactionsLocked: selectDisplayInteractionsLock(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSlideById: createDisplaySlideSwitchAction(dispatch),
        switchToNextSlide: () => createDisplaySlideSwitchAction(dispatch)('forward'),
        switchToPreviousSlide: () => createDisplaySlideSwitchAction(dispatch)('backward'),
    };
};

export const SlideWrap = connect(mapStateToProps, mapDispatchToProps)(SlideWrapComponent);
