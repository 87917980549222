import React from 'react';

import { PreviewMode } from '../../../store/containers/PreviewMode';

import { fetchSlideLayout } from '../../../store/actions';

export class PreviewModeBootstrap {
    constructor(slideID) {
        this.slideID = slideID;
    }

    setup(store) {
        store.dispatch(fetchSlideLayout(this.slideID));
    }

    getComponent() {
        return <PreviewMode slideID={this.slideID} />;
    }
}
