import { put, select, takeEvery } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';

import { selectTileContentByID } from '../selectors';
import { fetchContent, storeContent } from '../actions';

function* fetchTickerContentSaga(action) {
    const { slideID, tileID, width, height } = action.payload;

    const state = yield select();

    if (selectTileContentByID(slideID, tileID, state) != null) {
        return;
    }

    const response = yield retryRequest(() =>
        API.fetchTileContent(slideID, tileID, width, height)
    );

    yield put(
        storeContent({
            ...response,
            slideID,
        })
    );
}

function* watchContentActions() {
    yield takeEvery(fetchContent.type, fetchTickerContentSaga);
}

export const ContentSagas = [watchContentActions];
