import { createAction } from '@reduxjs/toolkit';

export const fetchContent = createAction(
    'content/fetchContent',
    (slideID, tileID, width, height) => {
        return {
            payload: {
                slideID,
                tileID,
                width,
                height,
            },
        };
    }
);

export const removeContent = createAction('content/removeContent', (slideID, tileID) => {
    return {
        payload: {
            slideID,
            tileID,
        },
    };
});

export const storeContent = createAction('content/storeContent', (metadata) => {
    return {
        payload: metadata,
    };
});
