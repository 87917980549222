import React from 'react';
import PropTypes from 'prop-types';

import Ripple from 'react-ripples';

import { noop } from 'lodash-es';

import './SlidePreview.scss';

export const SlidePreview = ({ locked, colors, slides, onSwitch }) => {
    const children = slides.map((slide, index) => (
        <div
            className={`slide-preview__item ${locked ? 'slide-preview__item--locked' : ''}`}
            style={{
                borderColor: colors?.border ?? 'white',
                background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('${slide.previewImage}')`,
            }}
            key={index}
        >
            <Ripple
                className="slide-preview__text-wrapper full-size"
                onClick={locked ? noop : () => onSwitch(slide.id)}
            >
                <span>{slide.text}</span>
            </Ripple>
        </div>
    ));

    return (
        <div
            className="slide-preview"
            style={{
                backgroundColor: colors?.background ?? 'white',
                borderTop: `2px solid ${colors?.border}`,
            }}
        >
            <div className="slide-preview--scroll-container">
                <div className="slide-preview--flex-container">{children}</div>
            </div>
        </div>
    );
};

SlidePreview.propTypes = {
    colors: PropTypes.object,
    locked: PropTypes.bool.isRequired,
    onSwitch: PropTypes.func.isRequired,
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
            previewImage: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};
