import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PDFPage } from './PDFDocument';

import { SwitchTransition } from '../../animation/SwitchTransition';
import { resolveAnimationFromName } from '../../animation/AnimationDefnitions';

import './PDF.scss';

export class PDF extends Component {
    constructor(props) {
        super(props);

        this.setPages();
        this.scrollTime = this.props.content.scrollTime * 1000 || 10000;

        this.state = {
            active: false,
        };
    }

    setPages() {
        const { startPage, pages } = this.props.content;

        this.uniquePages = pages
            .filter((page) => page != null)
            .map((page, index) => ({ url: page, id: index }));

        for (let i = startPage - 1; i > 0; i--) {
            const first = this.uniquePages.splice(0, 1)[0];

            this.uniquePages.push(first);
        }
    }

    componentDidMount() {
        this.tryStartPageSwitch();
    }

    componentDidUpdate(previousProps) {
        if (previousProps.displayMode.isPreload !== this.props.displayMode.isPreload) {
            this.tryStartPageSwitch();
        }

        if (previousProps.pages !== this.props.content.pages) {
            this.setPages();
        }
    }

    tryStartPageSwitch = () => {
        if (this.props.displayMode.isPreload) {
            this.setState({ active: false });
        } else {
            this.setState({ active: true });
        }
    };

    render() {
        const { autoZoom, zoomValue, transition, pages } = this.props.content;

        if (!Array.isArray(pages) || pages.length <= 0 || this.uniquePages.length <= 0) {
            return null;
        }

        // This wrapper div needs to be there! Since the switch transition renders
        // its items directly into the "content" div, which is position relative,
        // which causes it to ignore paddings. We need the switch transition to be
        // position absolute though for transitions
        return (
            <div className="full-size">
                <SwitchTransition
                    preload
                    items={this.uniquePages}
                    timeout={this.scrollTime}
                    active={this.state.active}
                    transition={resolveAnimationFromName(transition)}
                    className="full-size center pdf"
                    render={({ item: page }) => (
                        <PDFPage
                            key={'PDF__DOCUMENT'}
                            scaleMode={autoZoom}
                            zoomValue={zoomValue}
                            file={page.url}
                        />
                    )}
                />
            </div>
        );
    }
}

PDF.propTypes = {
    content: PropTypes.object.isRequired,
    displayMode: PropTypes.object.isRequired,
};
