import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Resource = ({ resource, canLoad, load, placeholder, render }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (resource == null && canLoad && !isLoading) {
            load();
            setIsLoading(true);
        }

        if (resource != null && isLoading) {
            setIsLoading(false);
        }
    }, [canLoad, isLoading, load, resource]);

    if (resource == null) {
        return placeholder ?? <div></div>;
    }

    return render(resource);
};

Resource.propTypes = {
    placeholder: PropTypes.element,
    resource: PropTypes.any,
    canLoad: PropTypes.bool,
    load: PropTypes.func.isRequired,
    loader: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
};
