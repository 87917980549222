import { createReducer } from '@reduxjs/toolkit';

import { storeResource } from '../actions';

export const resourceReducer = createReducer(
    {
        byCacheKey: {},
    },
    {
        [storeResource.type]: (state, action) => {
            const { resource, cacheKey } = action.payload;

            if (cacheKey == null) {
                return;
            }

            state.byCacheKey[cacheKey] = resource;
        },
    }
);
