import React from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';
import { Colors } from '../../../common/Colors';

import './Greeting.scss';

function formatDate(input) {
    return DateTime.fromFormat(input, 'yyyy-LL-dd TT')
        .setLocale('de')
        .toFormat('cccc, dd.LL.yyyy T');
}

function mapTextIndexToColorIndex(index) {
    if (index === 0) {
        return 1;
    }

    if (index === 1) {
        return 2;
    }

    return 3;
}

export const Greeting = ({ content }) => {
    const colors = Colors.fromSlotContent(content);

    return (
        <div className="greeting full-size">
            <div className="greeting__header">
                {[content.row1, content.row2, content.row3, content.row4]
                    .filter((entry) => entry != null && entry !== '')
                    .map((text, index) => [
                        text,
                        {
                            color: colors.font(mapTextIndexToColorIndex(index)),
                            background: colors.background(mapTextIndexToColorIndex(index)),
                            fontSize: '3rem',
                        },
                    ])
                    .map(([text, styles], index) => (
                        <div key={index} className="greeting__header__text-row">
                            <span style={styles}>{text}</span>
                        </div>
                    ))}

                {content.logo && (
                    <div className="greeting__header__logo">
                        <img
                            width={content.logo.width}
                            height={content.logo.height}
                            alt="Logo bild"
                            src={content.logo.url}
                        />
                    </div>
                )}
            </div>

            <hr style={{ background: colors.background(4, 'black') }} />

            <div className="greeting__footer" style={{ borderColor: colors.background(4) }}>
                <div className="greeting__footer--item">
                    <i
                        style={{ color: colors.font(4) }}
                        className="far fa-thumbtack greeting--icon"
                    ></i>
                    <span style={{ color: colors.font(0) }}>{content.title}</span>
                </div>

                <div className="greeting__footer--item">
                    <i
                        style={{ color: colors.font(4) }}
                        className="far fa-calendar-day greeting--icon"
                    ></i>

                    <span style={{ color: colors.font(0) }}>
                        {formatDate(content.start)} Uhr
                    </span>

                    {content.start !== content.end && (
                        <span style={{ color: colors.font(0) }}>
                            {' '}
                            bis {formatDate(content.end)} Uhr
                        </span>
                    )}
                </div>

                <div className="greeting__footer--item">
                    <i
                        style={{ color: colors.font(4) }}
                        className="far fa-map-marker-alt greeting--icon"
                    ></i>
                    <span style={{ color: colors.font(0) }}>{content.room}</span>
                </div>
            </div>
        </div>
    );
};

Greeting.propTypes = {
    content: PropTypes.object.isRequired,
};
