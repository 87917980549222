import { connect } from 'react-redux';

import { Resource as ResourceComponent } from '../../components/helper/Resource';

import { selectResourceByCacheKey, selectTileContentByID } from '../selectors';

export const createResource = (selector) => {
    return connect(
        (state, props) => {
            const stored = selector(props.loader().payload, state, props);

            return {
                resource: stored,
            };
        },
        (dispatch, props) => {
            return {
                load: () => dispatch(props.loader()),
            };
        }
    )(ResourceComponent);
};

export const ImageResource = createResource((payload, state) =>
    selectResourceByCacheKey(payload.cacheKey, state)
);

export const ContentResource = createResource((payload, state) =>
    selectTileContentByID(payload.slideID, payload.tileID, state)
);
