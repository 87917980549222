import { put, delay, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';

import {
    swapSlides,
    updateSlide,
    fetchChanges,
    removeLayout,
    removeContent,
    fetchSlideLayout,
    updateLayoutMeta,
    scheduleSlideSwap,
    startChangesFetch,
    fetchTickerContent,
    setLastSlideUpdateTime,
    setLastTickerUpdateTime,
    cancelScheduledSlideSwap,
} from '../actions';

import {
    selectLayoutByID,
    selectTickerState,
    selectDisplaySlide,
    selectTileContentByID,
    selectAllContentTilesBySlideID,
    selectLastSlideUpdateTimestamp,
    selectLastTickerUpdateTimestamp,
    selectInitialTickerRenderTimestamp,
    selectInitialDisplaySlideRenderTimestamp,
} from '../selectors';

function mapTickerChangesToActions(tickerChanges, displaySlide) {
    const actions = [];

    if (tickerChanges === true) {
        actions.push(setLastTickerUpdateTime(), fetchTickerContent(displaySlide.slideID));
    }

    return actions;
}

function mapSlideChangesToActions(slideChanges, displaySlide) {
    const actions = [];

    if (
        typeof slideChanges !== 'object' ||
        Object.keys(slideChanges).every((key) => !slideChanges[key])
    ) {
        return [];
    }

    actions.push(setLastSlideUpdateTime());

    if (slideChanges.reload === true) {
        window.location.reload();
    }

    if (slideChanges.next === true) {
        actions.push(cancelScheduledSlideSwap(), swapSlides(), scheduleSlideSwap());
    }

    if (typeof slideChanges.newRemainingDuration === 'number') {
        actions.push(
            cancelScheduledSlideSwap(),
            scheduleSlideSwap(slideChanges.newRemainingDuration / 1000)
        );
    }

    if (typeof slideChanges.newDuration === 'number') {
        actions.push(
            updateSlide(displaySlide.slideID, {
                duration: slideChanges.newDuration / 1000,
            })
        );
    }

    if (typeof slideChanges.newLayoutID === 'number') {
        actions.push(
            removeLayout(displaySlide.slideID),
            fetchSlideLayout(displaySlide.slideID)
        );
    }

    if (
        typeof slideChanges.newBorderAround === 'string' ||
        typeof slideChanges.newBackgroundColor === 'string' ||
        typeof slideChanges.newBackgroundImageID === 'number' ||
        typeof slideChanges.newBackgroundImageURL === 'string'
    ) {
        const updates = [
            ['newBorderAround', 'borderAround'],
            ['newBackgroundColor', 'backgroundColor'],
            ['newBackgroundImageID', 'backgroundImageID'],
            ['newBackgroundImageURL', 'backgroundImageURL'],
        ]
            .filter((key) => slideChanges[key[0]] !== false)
            .map((key) => ({ [key[1]]: slideChanges[key[0]] }))
            .reduce((acc, current) => Object.assign(acc, current), {});

        actions.push(updateLayoutMeta(displaySlide.slideID, updates));
    }

    if (Array.isArray(slideChanges.changedTiles)) {
        actions.push(
            ...slideChanges.changedTiles.map((tileID) =>
                removeContent(displaySlide.slideID, tileID)
            )
        );
    }

    return actions;
}

function* fetchChangesSaga() {
    const state = yield select();
    const displaySlide = selectDisplaySlide(state);

    if (displaySlide == null) {
        return;
    }

    const displayLayout = selectLayoutByID(displaySlide.slideID, state);

    if (displayLayout == null) {
        return;
    }

    const tickerChanges = yield retryRequest(() =>
        API.fetchTickerChanges(
            selectInitialTickerRenderTimestamp(state),
            selectLastTickerUpdateTimestamp(state),
            selectTickerState(state),
            displaySlide.slideID
        )
    );

    const slideChanges = yield retryRequest(() =>
        API.fetchSlideChanges(
            displaySlide.slideID,
            selectInitialDisplaySlideRenderTimestamp(state),
            selectLastSlideUpdateTimestamp(state),
            displayLayout.meta.id,
            displaySlide.duration,
            Object.fromEntries(
                selectAllContentTilesBySlideID(displaySlide.slideID, state).map((tile) => [
                    `tile${tile.contentID}`,
                    selectTileContentByID(displaySlide.slideID, tile.contentID, state)?.id,
                ])
            ),
            displayLayout.meta.backgroundImageID,
            displayLayout.meta.backgroundColor,
            displayLayout.meta.borderAround,
            API.isPreviewMode()
        )
    );

    const actions = [
        ...mapSlideChangesToActions(slideChanges, displaySlide),
        ...mapTickerChangesToActions(tickerChanges, displaySlide),
    ];

    for (const action of actions) {
        yield put(action);
    }
}

function* fetchChangesLoop() {
    while (true) {
        yield delay(10_000);
        yield put(fetchChanges());
    }
}

function* watchChangeActions() {
    yield takeEvery(fetchChanges.type, fetchChangesSaga);
    yield takeLatest(startChangesFetch.type, fetchChangesLoop);
}

export const ChangeSagas = [watchChangeActions];
