import { createAction } from '@reduxjs/toolkit';

/**
 * (Saga-Action)
 * Fetches a layout by ID
 */
export const fetchSlideLayout = createAction('layout/fetchSlideLayout', (slideID) => {
    return {
        payload: {
            slideID,
        },
    };
});

/**
 * (Saga-Action)
 * Stores a given layout.
 */
export const storeLayout = createAction('layout/storeLayout', (layout) => {
    return {
        payload: layout,
    };
});

/**
 * (Store-Action)
 * Remoes a stored layout from the cache
 */
export const removeLayout = createAction('layout/removeLayout', (slideID) => {
    return {
        payload: {
            slideID,
        },
    };
});

/**
 * (Store-Action)
 * Inserts the "updates" values into the layouts's meta.
 */
export const updateLayoutMeta = createAction('layout/updateLayoutMeta', (slideID, updates) => {
    return {
        payload: {
            slideID,
            updates,
        },
    };
});
