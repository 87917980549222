import React from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import './animations.scss';

export const AnimatedToggle = ({ animation, active, timeout, a, b }) => {
    const components = [
        { name: 'a', component: a, isActive: active === 'a' },
        { name: 'b', component: b, isActive: active === 'b' },
    ]
        .sort((x, y) => Number(x.isActive) - Number(y.isActive))
        .map((def) => (
            <CSSTransition
                key={`transition-${def.name}`}
                in={!def.isActive}
                timeout={timeout ?? 0}
                classNames={animation}
            >
                {def.component}
            </CSSTransition>
        ));

    return <>{components}</>;
};

AnimatedToggle.propTypes = {
    a: PropTypes.element.isRequired,
    b: PropTypes.element.isRequired,
    active: PropTypes.string.isRequired,
    animation: PropTypes.string.isRequired,
    timeout: PropTypes.number,
};
