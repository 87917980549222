import React from 'react';
import PropTypes from 'prop-types';

import ReactTicker from 'react-ticker';

import { Colors } from '../../../common/Colors';
import { CSSHelp } from '../../../common/CSSHelp';

import { CSSVariableProvider } from '../../helper/CSSVariableProvider';
import { NewsTickerElement } from './NewsTickerElement';

import './NewsTicker.scss';

export const NewsTicker = ({ position, height, colors, float, transparency, entries }) => {
    const components = entries.map(({ content, bold }, index) => (
        <NewsTickerElement key={index} bold={bold} content={content} />
    ));

    return (
        <div
            className={CSSHelp.multiclass(
                `news-ticker`,
                `news-ticker--${position}`,
                float ? `news-ticker--float` : 'news-ticker--no-float'
            )}
            style={{
                height: `${height}px`,
                backgroundColor: Colors.hexToRGBA(colors.background, transparency / 100).css,
                borderColor: colors.border,
                color: colors.font,
            }}
        >
            <CSSVariableProvider variables={{ 'ticker-height': `${height}px` }}>
                <ReactTicker offset={0}>
                    {({ index }) => components[index % components.length]}
                </ReactTicker>
            </CSSVariableProvider>
        </div>
    );
};

NewsTicker.propTypes = {
    position: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    float: PropTypes.bool.isRequired,
    transparency: PropTypes.number.isRequired,
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string.isRequired,
            bold: PropTypes.bool.isRequired,
        })
    ).isRequired,
};
