import React from 'react';
import PropTypes from 'prop-types';

import './Text.scss';

export const Text = ({ content }) => {
    return (
        <div className="wsiwyg-text center">
            <div dangerouslySetInnerHTML={{ __html: content.Text }}></div>
        </div>
    );
};

Text.propTypes = {
    content: PropTypes.object.isRequired,
};
