import { createReducer } from '@reduxjs/toolkit';

import { storeLayout, updateLayoutMeta, removeLayout } from '../actions';

export const layoutReducer = createReducer(
    {
        bySlideId: {},
    },
    {
        [storeLayout.type]: (state, action) => {
            state.bySlideId[action.payload.slideID] = action.payload;
        },
        [updateLayoutMeta.type]: (state, action) => {
            const { slideID, updates } = action.payload;

            state.bySlideId[slideID].meta = {
                ...state.bySlideId[slideID].meta,
                ...updates,
            };
        },
        [removeLayout.type]: (state, action) => {
            const { slideID } = action.payload;

            delete state.bySlideId[slideID];
        },
    }
);
