import { safeSpawn } from './effects';

import { SlideSagas } from './slide.saga';
import { LayoutSagas } from './layout.saga';
import { TickerSagas } from './ticker.saga';
import { ChangeSagas } from './changes.saga';
import { DisplaySagas } from './display.saga';
import { ContentSagas } from './content.saga';
import { ResourceSagas } from './resource.saga';

export function* rootSaga() {
    const sagas = [
        ...SlideSagas,
        ...ChangeSagas,
        ...TickerSagas,
        ...LayoutSagas,
        ...ContentSagas,
        ...DisplaySagas,
        ...ResourceSagas,
    ];

    yield safeSpawn(sagas);
}
