import { useEffect } from 'react';
import { throttle } from 'lodash-es';

/**
 * Generic function to add a event-listener to an element
 *
 * @param {EventTarget} target The target you want to attach the event listener to
 * @param {string} name The name of the event
 * @param {function(): void} callback The function to call when the event is triggered
 * @param {number|null} throttleTime The time to throttle the event
 */
export const useEventListener = (target, name, callback, throttleTime = null) => {
    useEffect(() => {
        const handle = throttle(callback, throttleTime);

        target.addEventListener(name, handle);

        return () => {
            target.removeEventListener(name, handle);
        };
    }, [callback, name, target, throttleTime]);
};
