import { put, takeEvery } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';
import { fetchTickerContent, storeTickerContent } from '../actions';

function* fetchTickerContentSaga() {
    const response = yield retryRequest(() => API.fetchTickerContent());

    yield put(storeTickerContent(response));
}

function* watchTickerActions() {
    yield takeEvery(fetchTickerContent.type, fetchTickerContentSaga);
}

export const TickerSagas = [watchTickerActions];
