import { put, takeEvery } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';
import { fetchSlideLayout, storeLayout } from '../actions';

function* fetchSlideLayoutSaga(action) {
    const { slideID } = action.payload;

    const response = yield retryRequest(() => API.fetchSlideLayout(slideID));

    yield put(
        storeLayout({
            ...response,
            slideID,
        })
    );
}

function* watchLayoutActions() {
    yield takeEvery(fetchSlideLayout.type, fetchSlideLayoutSaga);
}

export const LayoutSagas = [watchLayoutActions];
