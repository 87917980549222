import React from 'react';

export const ErrorDisplay = () => {
    // TODO: This need some polish :)
    return (
        <div>
            <h1>Oh no!</h1>
            <span>
                An unexpected error occured!
                <br />
                The issue has been reported and we are working on a fix!
            </span>
        </div>
    );
};
