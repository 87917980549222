import React from 'react';
import PropTypes from 'prop-types';

export const VimeoVideo = ({ content }) => (
    <iframe
        title={`vimeo-video-embed-${content.id}`}
        width="100%"
        height="100%"
        src={`https://player.vimeo.com/video/${content.videoID}?autoplay=1&autopause=0&controls=0&loop=1&title=0&byline=0&portrait=0&background=${content.mute}`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
    ></iframe>
);

VimeoVideo.propTypes = {
    content: PropTypes.object.isRequired,
};
