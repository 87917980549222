import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { noop } from 'lodash-es';
import { useTransition, animated } from 'react-spring';

/**
 * TODO: The whole animation system needs a rewrite.
 */

export const SwitchTransition = ({
    transition,
    timeout,
    items,
    preload,
    active,
    extras,
    overlay,
    render,
    className,
    style,
}) => {
    const [indexes, setIndex] = useState([0]);

    const current = items[indexes[0]];
    const next = indexes.length > 1 ? items[indexes[1]] : null;

    const transitions = useTransition(current, (item) => item.id, {
        ...transition,
        ...extras,
    });

    useEffect(() => {
        if (!active) {
            return noop;
        }

        const intervalID = setInterval(() => {
            setIndex((state) => [
                (state[0] + 1) % items.length,
                (state[0] + 2) % items.length,
            ]);
        }, timeout || 5000);

        return () => {
            clearTimeout(intervalID);
        };
    });

    return (
        <>
            {transitions.map(({ item, props: elementProps, key }) => (
                <animated.div
                    key={key}
                    className={className}
                    style={{
                        ...elementProps,
                        ...style,

                        position: overlay ? 'absolute' : '',
                    }}
                >
                    {render({ item })}
                </animated.div>
            ))}
            {preload && next != null && (
                <div className="full-size hidden preload">{render({ item: next })}</div>
            )}
        </>
    );
};

SwitchTransition.propTypes = {
    transition: PropTypes.object.isRequired,
    timeout: PropTypes.number,
    items: PropTypes.array.isRequired,
    active: PropTypes.bool,
    preload: PropTypes.bool,
    extras: PropTypes.object,
    overlay: PropTypes.bool,
    render: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};
