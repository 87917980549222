import { connect } from 'react-redux';

import { AutomatedMode as AutomatedModeComponenent } from '../../components/modes/AutomatedMode';

import { getSlideIndexes } from '../reducers/slide.reducer';
import { selectDisplaySchedule, selectSlideByID, selectLayoutByID } from '../selectors';

const mapStateToProps = (state) => {
    const displaySchedule = selectDisplaySchedule(state);

    const [displaySlideIndex, preloadSlideIndex] = getSlideIndexes(state.slides);

    return {
        schedule: displaySchedule,
        // We need to pass these objects one by one
        // since creating new objects will cause a render
        displaySlide: selectSlideByID(displaySchedule[displaySlideIndex].slideID, state),
        displayLayout: selectLayoutByID(displaySchedule[displaySlideIndex].slideID, state),
        preloadSlide: selectSlideByID(displaySchedule[preloadSlideIndex].slideID, state),
        preloadLayout: selectLayoutByID(displaySchedule[preloadSlideIndex].slideID, state),
        isPreview: false,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const AutomatedMode = connect(
    mapStateToProps,
    mapDispatchToProps
)(AutomatedModeComponenent);
