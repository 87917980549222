export const selectLayouts = (state) => state.layouts;

export const selectLayoutByID = (slideID, state) => {
    const layouts = selectLayouts(state);

    if (slideID == null) {
        return null;
    }

    return layouts.bySlideId[slideID];
};

export const selectAllContentTilesBySlideID = (slideID, state) => {
    const layout = selectLayoutByID(slideID, state);
    const contents = [];

    const walk = (node) => {
        if (node.contentID != null) {
            contents.push(node);
        }

        node.children.forEach((child) => {
            walk(child);
        });
    };

    walk(layout.layout);

    return contents;
};
