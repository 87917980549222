import { retry } from 'redux-saga/effects';

/**
 * Helper-Effect to retry a request.
 *
 * @param {Function} fn The function to retry
 * @param {number} maxRetries How often the request should be retired in case of error
 * @param {number} retryDelay The delay in between retries
 * @param {any[]} args Arguments for the function
 * @returns {object}
 */
export function retryRequest(fn, maxRetries = Infinity, retryDelay = 10000, ...args) {
    return retry(maxRetries, retryDelay, fn, ...args);
}
