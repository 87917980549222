const selectChanges = (state) => state.changes;

export const selectInitialTickerRenderTimestamp = (state) => {
    return selectChanges(state).ticker.initialRenderTime;
};

export const selectInitialDisplaySlideRenderTimestamp = (state) => {
    return selectChanges(state).slide.initialRenderTime;
};

export const selectLastSlideUpdateTimestamp = (state) => {
    return selectChanges(state).slide.lastUpdateTime;
};

export const selectLastTickerUpdateTimestamp = (state) => {
    return selectChanges(state).ticker.lastUpdateTime;
};
