import React from 'react';
import PropTypes from 'prop-types';

export const StyleRules = ({ condition, content }) => {
    if (condition === false) {
        return null;
    }

    return content && <style>{content}</style>;
};

StyleRules.propTypes = {
    condition: PropTypes.bool,
    content: PropTypes.string,
};
