import React from 'react';
import PropTypes from 'prop-types';

import { SlideWrap } from '../../store/containers/SlideWrap';
import { AnimatedToggle } from '../animation/AnimatedToggle';
import { Slide, DISPLAY_MODE_CONTEXT, PRELOAD_MODE_CONTEXT } from '../blocks/Slide';

export const AutomatedMode = ({
    schedule,
    displaySlide,
    displayLayout,
    preloadSlide,
    preloadLayout,
}) => {
    const slideComponents = schedule.map((definition) => (
        <Slide
            displayMode={definition.active ? DISPLAY_MODE_CONTEXT : PRELOAD_MODE_CONTEXT}
            slide={definition.active ? displaySlide : preloadSlide}
            layout={definition.active ? displayLayout : preloadLayout}
            key={`slide_${definition.id}`}
        />
    ));

    return (
        <div className={`full-size`}>
            <SlideWrap>
                <AnimatedToggle
                    active={schedule[0].active ? 'a' : 'b'}
                    animation={displaySlide?.transition ?? 'none'}
                    timeout={1500}
                    a={slideComponents[1]}
                    b={slideComponents[0]}
                />
            </SlideWrap>
        </div>
    );
};

AutomatedMode.propTypes = {
    schedule: PropTypes.array.isRequired,
    displaySlide: PropTypes.object,
    displayLayout: PropTypes.object,
    preloadSlide: PropTypes.object,
    preloadLayout: PropTypes.object,
};
