import * as Sentry from '@sentry/react';
import { Configuration } from './Configuration';

import { LogLevel } from './helpers/LogLevel';

export const LogLevels = {
    TRACE: new LogLevel(0, 'TRACE'),
    VERBOSE: new LogLevel(1, 'VERBOSE'),
    DEBUG: new LogLevel(2, 'DEBUG'),
    INFO: new LogLevel(3, 'INFO'),
    WARN: new LogLevel(4, 'WARN'),
    ERROR: new LogLevel(5, 'ERROR'),
    CRITICAL: new LogLevel(6, 'CRITICAL'),
    fromString(input) {
        for (const key of Object.keys(this)) {
            if (key.toLowerCase() === input.toLowerCase()) {
                return this[key];
            }
        }

        throw new Error(`Could not convert "${input}" to log-level`);
    },
};

export class Logger {
    static minimumLogLevel = LogLevels.INFO;

    static _localLogger = new Logger('Logger');

    static setLogLevel(level) {
        this.minimumLogLevel = LogLevels.INFO;
        this._localLogger.info('The log-level was set to', level.name);

        Logger.minimumLogLevel = level;
    }

    constructor(namespace) {
        this.namespace = namespace;
    }

    format(level, messages) {
        let error = null;

        if (messages[messages.length - 1] instanceof Error) {
            [error] = messages.splice(messages.length - 1, 1);
        }

        const timestamp = new Date().toLocaleTimeString();
        const formatParts = [`[${this.namespace}] (${timestamp}) [${level}]`, ...messages];

        if (error) {
            formatParts.push(`\n--- ERROR ---\n${error.stack}`);
        }

        return formatParts;
    }

    /**
     * Performs the actual logging of a message
     *
     * @private
     * @param {LogLevel} level The level that this message should be logged at
     * @param {string[]} messages The message parts
     * @returns {{ capture: Function }};
     */
    log(level, messages) {
        if (Logger.minimumLogLevel.severity > level.severity) {
            return {
                capture() {
                    /* Do nothing */
                },
            };
        }

        const messageParts = this.format(level.name, messages);

        switch (level.severity) {
            case LogLevels.TRACE.severity:
                console.trace(...messageParts);
                break;
            case LogLevels.VERBOSE.severity:
            case LogLevels.DEBUG.severity:
            case LogLevels.INFO.severity:
                console.log(...messageParts);
                break;
            case LogLevels.WARN.severity:
                console.warn(...messageParts);
                break;
            case LogLevels.ERROR.severity:
            case LogLevels.CRITICAL.severity:
                console.error(...messageParts);
                break;
            default:
                Logger._localLogger.warn('Invalid log-level dispatched!', ...messageParts);
        }

        const self = this;

        return {
            capture() {
                if (!Configuration.isProductionBuild()) {
                    return;
                }

                try {
                    Sentry.captureMessage(
                        messageParts.map((part) => JSON.stringify(part)).join(' ')
                    );
                } catch (e) {
                    self.warn('Could not report error to sentry', e);
                }
            },
        };
    }

    /**
     * Captures a new Breadcrumb for sentry
     *
     * @param {string} message Message of the new breadcrumb
     * @param {any} data Additional data to attach to the breadcrumb
     */
    captureBreadcrumb(message, data) {
        Sentry.addBreadcrumb({
            type: 'info',
            category: 'logger',
            level: Sentry.Severity.Info,
            message,
            data,
        });
    }

    trace(...messages) {
        return this.log(LogLevels.TRACE, messages);
    }

    verbose(...messages) {
        return this.log(LogLevels.VERBOSE, messages);
    }

    debug(...messages) {
        return this.log(LogLevels.DEBUG, messages);
    }

    info(...messages) {
        return this.log(LogLevels.INFO, messages);
    }

    warn(...messages) {
        return this.log(LogLevels.WARN, messages);
    }

    error(...messages) {
        return this.log(LogLevels.ERROR, messages);
    }

    critical(...messages) {
        return this.log(LogLevels.CRITICAL, messages);
    }
}
