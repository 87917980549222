import { connect } from 'react-redux';

import { PreviewMode as PreviewModeComponent } from '../../components/modes/PreviewMode';

import { selectLayoutByID } from '../selectors';

const mapStateToProps = (state, props) => {
    const { slideID } = props;

    return {
        slide: props,
        layout: selectLayoutByID(slideID, state),
        isPreview: false,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const PreviewMode = connect(mapStateToProps, mapDispatchToProps)(PreviewModeComponent);
