import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CSSHelp } from '../../../common/CSSHelp';

import { ImageResource } from '../../../store/containers/Resource';
import { fetchImage } from '../../../store/actions';

import './SingleImage.scss';

export const SingleImage = ({ source, content }) => {
    const memoizedLoader = useCallback(() => {
        return fetchImage(source);
    }, [source]);

    const memoizedRender = useCallback(
        (resolvedSource) => {
            return (
                <img
                    className={CSSHelp.multiclass(
                        'single-image',
                        content.displayAnimation
                            ? `animation--${content.displayAnimation}`
                            : ''
                    )}
                    src={resolvedSource.url}
                    alt=""
                />
            );
        },
        [content.displayAnimation]
    );

    return <ImageResource canLoad={true} loader={memoizedLoader} render={memoizedRender} />;
};

SingleImage.propTypes = {
    source: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
};
