import React from 'react';
import PropTypes from 'prop-types';

import './Row.scss';

export const Row = (props) => {
    return (
        <div className="row" style={{ height: `${props.height}%` }}>
            {props.children}
        </div>
    );
};

Row.propTypes = {
    // The height of the row in percent, relative to its parent.
    height: PropTypes.number.isRequired,
    // The children of this row
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
