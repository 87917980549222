import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { AspectRatio, BoxSize } from '@digitalcastle/aspect-ratio-box';

const { GlobalWorkerOptions, PDFWorker, getDocument } = require('pdfjs-dist/build/pdf');

GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const PDF_WORKER = new PDFWorker({ name: 'PDF_RENDER_WORKER' });

function getPageScaleFromBoxSize(page, size) {
    const { width, height } = size;
    const viewport = page.getViewport({ scale: 1 });

    return width ? width / viewport.width : height / viewport.height;
}

function scalePdfAccordingToMode(pdf, container, mode, zoom) {
    const initialViewport = pdf.getViewport({ scale: 1 });

    const containerHeight = container.offsetHeight;
    const containerWidth = container.offsetWidth;

    const size = { width: null, height: null };

    const aspectRatio = new AspectRatio(
        new BoxSize(initialViewport.width, initialViewport.height)
    );

    switch (mode) {
        case 'height':
            size.height = containerHeight;
            size.width = aspectRatio.scaleWidth(containerHeight);
            break;
        case 'width':
            size.height = aspectRatio.scaleHeight(containerWidth);
            size.width = containerWidth;
            break;
        case 'both': {
            const elementHeightWithContainerWidth = aspectRatio.scaleHeight(containerWidth);
            const elementWidthWithContainerHeight = aspectRatio.scaleWidth(containerHeight);

            if (elementHeightWithContainerWidth <= containerHeight) {
                size.height = elementHeightWithContainerWidth;
                size.width = aspectRatio.scaleWidth(size.height);
            }

            if (elementWidthWithContainerHeight <= containerWidth) {
                size.width = elementWidthWithContainerHeight;
                size.height = aspectRatio.scaleHeight(size.width);
            }

            break;
        }
        case 'none': {
            const viewportWithUserScale = pdf.getViewport({
                scale: zoom / 100,
            });

            size.height = viewportWithUserScale.height;
            size.width = viewportWithUserScale.width;

            break;
        }
        default:
            return scalePdfAccordingToMode('both');
    }

    return size;
}

export class PDFPage extends PureComponent {
    constructor(props) {
        super(props);

        this.isRendering = false;
        this.destroyables = [];
        this.targetCanvasRef = React.createRef();
    }

    componentDidMount() {
        this.loadPDF();
    }

    componentDidUpdate() {
        this.loadPDF();
    }

    componentWillUnmount() {
        this.cleanup();
    }

    cleanup() {
        this.destroyables
            .filter((x) => x != null && typeof x.destroy === 'function')
            .forEach((x) => x.destroy());
    }

    loadPDF = () => {
        const { destroyables, targetCanvasRef, isRendering, props } = this;

        if (isRendering) {
            return;
        }

        this.cleanup();
        this.isRendering = true;

        const documentLoadTask = getDocument({
            url: props.file,
            worker: PDF_WORKER,
        });

        documentLoadTask.promise.then((doc) => {
            destroyables.push(doc);

            doc.getPage(1).then((page) => {
                destroyables.push(page);

                const canvas = targetCanvasRef.current;

                if (canvas == null || canvas.parentElement == null) {
                    return;
                }

                const size = scalePdfAccordingToMode(
                    page,
                    canvas.parentElement,
                    props.scaleMode,
                    props.zoomValue
                );

                canvas.height = size.height;
                canvas.width = size.width;

                const context = {
                    canvasContext: canvas.getContext('2d'),
                    viewport: page.getViewport({
                        scale: getPageScaleFromBoxSize(page, size),
                    }),
                };

                page.render(context);

                this.isRendering = false;
            });
        });
    };

    render() {
        return <canvas ref={this.targetCanvasRef} />;
    }
}

PDFPage.propTypes = {
    scaleMode: PropTypes.string.isRequired,
    zoomValue: PropTypes.number.isRequired,
    file: PropTypes.string.isRequired,
};
