import React from 'react';
import PropTypes from 'prop-types';

import { CSSHelp } from '../../common/CSSHelp';

import { Row } from '../layout/Row';
import { Column } from '../layout/Column';

import { Content } from './Content';

import './Slide.scss';

export const DISPLAY_MODE_CONTEXT = { isPreload: false };
export const PRELOAD_MODE_CONTEXT = { isPreload: true };

export class Slide extends React.PureComponent {
    createLayout(node) {
        const { displayMode, slide } = this.props;

        const children = [];

        for (const child of node.children) {
            children.push(this.createLayout(child));
        }

        if (node.contentID) {
            children.push(
                <Content
                    key={node.id}
                    displayMode={displayMode}
                    slideID={slide.slideID}
                    tileID={node.contentID}
                />
            );
        }

        switch (node.type) {
            case 'column':
                return (
                    <Column key={node.id} width={node.percentSize.width}>
                        {children}
                    </Column>
                );
            case 'row':
                return (
                    <Row key={node.id} height={node.percentSize.height}>
                        {children}
                    </Row>
                );
            default:
                throw new Error(`Invalid node type in Slide: ${JSON.stringify(node.type)}`);
        }
    }

    render() {
        const { displayMode, layout } = this.props;

        if (layout == null) {
            return <div className="full-size slide"></div>;
        }

        const { layout: displayLayout, meta } = layout;

        const styles = {};

        if (meta.backgroundColor) {
            styles.background = meta.backgroundColor;
        }

        if (meta.backgroundImageURL) {
            styles.background = `url(${meta.backgroundImageURL})`;
        }

        return (
            <div
                className={CSSHelp.multiclass(
                    'full-size slide',
                    `slide--border-${meta.borderAround}`,
                    `slide--${displayMode.isPreload ? 'preload' : 'display'}`
                )}
                style={styles}
            >
                {this.createLayout(displayLayout)}
            </div>
        );
    }
}

Slide.propTypes = {
    slide: PropTypes.object,
    layout: PropTypes.object,
    displayMode: PropTypes.shape({
        isPreload: PropTypes.bool.isRequired,
    }).isRequired,
};
