import React from 'react';
import PropTypes from 'prop-types';

import { SingleImage } from './SingleImage';
import { SwitchTransition } from '../../animation/SwitchTransition';

import { resolveAnimationFromName } from '../../animation/AnimationDefnitions';

const ANIMATION_EXTRAS = {
    unique: true,
    native: true,
};

export const Diashow = ({ active, images, content }) => {
    const { displayDuration, transition } = content;

    const keyedImages = images.map((image, index) => ({ id: index, image }));

    return (
        <SwitchTransition
            overlay
            preload
            items={keyedImages}
            timeout={displayDuration}
            active={active}
            extras={ANIMATION_EXTRAS}
            transition={resolveAnimationFromName(transition)}
            render={({ item }) => <SingleImage content={content} source={item.image} />}
        />
    );
};

Diashow.propTypes = {
    active: PropTypes.bool,
    images: PropTypes.array.isRequired,
    content: PropTypes.object.isRequired,
};
