import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@sentry/react';

import { useDimensions } from '../../hooks';

import { fetchContent } from '../../store/actions';
import { ContentResource } from '../../store/containers/Resource';

import { Logger } from '../../common/Logger';

import {
    PDF,
    Text,
    Video,
    Clock,
    Image,
    IFrame,
    Weather,
    Signpost,
    Greeting,
    Placeholder,
} from '../tiles';

import './Content.scss';

const LOG = new Logger('Content');

function mapContentToComponent(content, props) {
    if (content == null) {
        return <Placeholder {...props} message="content == null" />;
    }

    const { type } = content;

    switch (type) {
        case 'ContentImage':
            return <Image {...props} />;
        case 'ContentWeather':
            return <Weather {...props} />;
        case 'ContentVideo':
            return <Video {...props} />;
        case 'ContentClock':
            return <Clock {...props} />;
        case 'ContentText':
            return <Text {...props} />;
        case 'ContentSignpost':
            return <Signpost {...props} />;
        case 'ContentGreeting':
            return <Greeting {...props} />;
        case 'ContentPDF':
            return <PDF {...props} />;
        case 'ContentIFrame':
            return <IFrame {...props} />;
        case 'ContentPlaceholder':
            return <Placeholder {...props} />;
        default:
            LOG.warn(`Invalid ContentType: "${type}" Props: ${JSON.stringify(props)}`);
            return <div></div>;
    }
}

const CONTENT_ERROR_FALLBACK = <div className="full-size"></div>;

export const Content = ({ displayMode, slideID, tileID }) => {
    const [container, dimensions] = useDimensions();

    const width = dimensions?.width;
    const height = dimensions?.height;

    const loaderAction = () => fetchContent(slideID, tileID, width, height);

    const render = (content) => {
        const styles = {};

        const background = content?.colors?.tile;
        switch (background?.type) {
            case 'image':
                styles.background = `url(${background.url})`;
                break;
            case 'color':
                styles.background = background.value;
                break;
            default:
                LOG.warn('Invalid content background type', background);
        }

        const border = content?.border;
        if (border && border.enabled) {
            for (const side of border.sides) {
                const jsxKey = `border${side.replace(side[0], side[0].toUpperCase())}`;

                styles[jsxKey] = `${border.color} ${border.width}px solid`;
            }
        }

        return (
            <div
                ref={container}
                style={styles}
                className={`content full-size center ${content.type}`}
            >
                {mapContentToComponent(content, {
                    content,
                    dimensions,
                    displayMode,
                })}
            </div>
        );
    };

    const memoizedPlaceholder = useMemo(
        () => <div ref={container} className="full-size content content--loading"></div>,
        [container]
    );

    return (
        <ErrorBoundary fallback={CONTENT_ERROR_FALLBACK}>
            <ContentResource
                canLoad={dimensions != null}
                loader={loaderAction}
                placeholder={memoizedPlaceholder}
                render={render}
            />
        </ErrorBoundary>
    );
};

Content.propTypes = {
    slideID: PropTypes.number.isRequired,
    tileID: PropTypes.number.isRequired,
    displayMode: PropTypes.shape({
        isPreload: PropTypes.bool.isRequired,
    }).isRequired,
};
