import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from './sagas/index';
import { rootReducer } from './reducers/index';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
    enhancers: [createReduxEnhancer()],
});

sagaMiddleware.run(rootSaga);
