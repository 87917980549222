const ERROR_MESSAGES = {
    noSlideFound: `Aktuell sind keine Folien hinzugefügt oder aktiviert! Bitte fügen sie mindestens eine Folie hinzu und stellen sie sicher dass diese aktiviert ist!`,
};

export class FetchError extends Error {
    /**
     * Constructs a new Fetch error
     *
     * @param {string} message The message for why the error ocurred.
     * @param {object} request The request that was made
     * @param {Response} response The response that was returned by the request
     * @param {object} urlSegments All url segments
     */
    constructor(message, request, response, urlSegments) {
        super(message);

        this.request = request;
        this.response = response;

        this.urlSegments = urlSegments;
        this.statusCode = this.response.status;
        this.message = this.inferHelpfullMessage();
    }

    /**
     * Tries to infer a helpfull message from the response,
     * if none is found, it will return the body.
     *
     * @returns {string} A helpfull message or `this.message`
     */
    inferHelpfullMessage() {
        switch (this.statusCode) {
            case 404:
                if (this.request.url.match(this.urlSegments.NEXT_SLIDE)) {
                    return ERROR_MESSAGES.noSlideFound;
                }

                return this.message;
            default:
                return this.message;
        }
    }
}
