import { createAction } from '@reduxjs/toolkit';

/**
 * (Store-Action)
 * Stores a fetched slide.
 */
export const storeSlide = createAction('slide/storeSlide', (metadata) => {
    return {
        payload: metadata,
    };
});

/**
 * (Store-Action)
 * Updates a given slide in the store
 */
export const updateSlide = createAction('slide/updateSlide', (slideID, updates) => {
    return {
        payload: {
            slideID,
            updates,
        },
    };
});

/**
 * (Saga-Action)
 * Special case for when setting up the Automated mode for the first time.
 */
export const setupInitialSlides = createAction('slides/setupInitialSlides');

/**
 * (Store-Action)
 * Puts the given slide into the display queue.
 */
export const enqueueSlide = createAction('slide/enqueueSlide', (slideID) => {
    return {
        payload: {
            slideID,
        },
    };
});

/**
 * (Store-Action)
 * Schedules the swapping of the preload and display slide.
 */
export const scheduleSlideSwap = createAction(
    'slide/scheduleSlideSwap',
    /**
     * @param {number|null} durationOverride By default schedules
     * a display-slide switch in the slides duration. Sometimes it
     * that time needs to be overwritten.
     * @returns {object} The action payload
     */
    (durationOverride = null) => {
        return {
            payload: {
                durationOverride,
            },
        };
    }
);

/**
 * (Saga-Action)
 * Makes the sagas fetch a new
 */
export const fetchNextSlide = createAction(
    'slide/fetchNextSlide',
    /**
     * Updates the display slide. A direction can be specified,
     * either 'forward' which means the next slide,
     * 'backward' which means the previous slide, or
     * <number> which means goto slide with that ID
     *
     * @param {"forward"|"backward"|number} direction
     * @returns {object} The action payload
     */
    (direction = 'forward') => {
        return {
            payload: {
                direction,
            },
        };
    }
);

/**
 * (Saga-Action)
 * Triggers the actual switch to the next slide for the given direction.
 */
export const updateDisplaySlide = createAction(
    'slide/updateDisplaySlide',
    /**
     * Updates the display slide. A direction can be specified,
     * either 'forward' which means the next slide,
     * 'backward' which means the previous slide, or
     * <number> which means goto slide with that ID
     *
     * @param {"forward"|"backward"|number} direction
     * @returns {object} The action payload
     */
    (direction = 'forward') => {
        return {
            payload: {
                direction,
            },
        };
    }
);

/**
 * (Saga-Action)
 * Cancels the scheduled slide swap that was scheduled by the `scheduleSlideSwap` action.
 */
export const cancelScheduledSlideSwap = createAction('slide/cancelScheduledSlideSwap');

/**
 * (Store-Action)
 * Swaps the preload and display slide.
 */
export const swapSlides = createAction('slide/swapSlides');

/**
 * (Store-Action)
 * Clears the slide queue
 */
export const clearQueue = createAction('slide/clearQueue');

/**
 * (Store-Action)
 * Takes the first slide from the store and sets it as the new preload slide.
 */
export const dequeueNewPreloadSlide = createAction('slide/dequeueNewPreloadSlide');

/**
 * (Store-Action)
 * Takes the first slide from the store and sets it as the new display slide.
 */
export const dequeueNewDisplaySlide = createAction('slide/dequeueNewDisplaySlide');
