import React from 'react';
import PropTypes from 'prop-types';

import { Diashow } from './Diashow';
import { SingleImage } from './SingleImage';

export const Image = ({ content, displayMode }) => {
    const { images } = content;

    if (images.length > 1) {
        return <Diashow active={!displayMode.isPreload} content={content} images={images} />;
    }

    if (images.length === 1) {
        return <SingleImage content={content} source={images[0]} />;
    }

    return <div></div>;
};

Image.propTypes = {
    content: PropTypes.object.isRequired,
    displayMode: PropTypes.object.isRequired,
};
