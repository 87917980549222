import { put, takeEvery } from 'redux-saga/effects';
import { retryRequest } from './effects';

import { API } from '../../common/API';
import { fetchDisplayMeta, storeDisplayMeta } from '../actions';

function* fetchDisplayMetaSaga() {
    const response = yield retryRequest(() => API.fetchDisplayMeta());

    yield put(storeDisplayMeta(response));
}

function* watchDisplayActions() {
    yield takeEvery(fetchDisplayMeta.type, fetchDisplayMetaSaga);
}

export const DisplaySagas = [watchDisplayActions];
