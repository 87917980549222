import React from 'react';
import PropTypes from 'prop-types';

import { FileVideo } from './FileVideo';
import { VimeoVideo } from './VimeoVideo';
import { YoutubeVideo } from './YoutubeVideo';

const mapPropsToComponent = (content, videoProps) => {
    switch (content.sourceToShow) {
        case 'vimeo':
            return <VimeoVideo {...videoProps} />;
        case 'youtube':
            return <YoutubeVideo {...videoProps} />;
        case 'file':
            return <FileVideo {...videoProps} />;
        default:
            return <div></div>;
    }
};

export const Video = ({ content, displayMode }) => {
    const videoProps = {
        play: !displayMode.isPreload,
        content,
    };

    // The div wrapper needs to be here! - This prevents a issue when unmounting the component
    return <div className="full-size">{mapPropsToComponent(content, videoProps)}</div>;
};

Video.propTypes = {
    content: PropTypes.object.isRequired,
    displayMode: PropTypes.object.isRequired,
};
