import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import fitty from 'fitty';

import { useElementUUID } from '../../hooks';

export const FitText = ({ children, options, className, style }) => {
    const containerID = useElementUUID('fit_text');

    useLayoutEffect(() => {
        const fitties = fitty(`#${containerID}`, options);

        setTimeout(() => {
            fitties.forEach((instance) => {
                instance.fit();
            });
        }, 250);

        return () => fitties.forEach((instance) => instance.unsubscribe());
    }, [containerID, options]);

    return (
        <div id={containerID} className={className} style={style}>
            {children}
        </div>
    );
};

FitText.propTypes = {
    options: PropTypes.shape({
        minSize: PropTypes.number,
        maxSize: PropTypes.number,
        multiLine: PropTypes.bool,
        observeMutations: PropTypes.any,
    }),
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
};
