import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as uuid from 'uuid';
import YouTubePlayer from 'youtube-player';

export class YoutubeVideo extends Component {
    constructor(props) {
        super(props);

        this.player = null;
        this.id = `youtube-player__${uuid.v4()}`;
    }

    componentDidMount() {
        const { content } = this.props;

        this.player = YouTubePlayer(this.id, {
            videoId: content.videoID,
            playerVars: {
                autoplay: 1,
                loop: 1,
                controls: 0,
                playlist: content.videoID,
                origin: window.location.origin,
            },
        });

        this.updatePlayerState();
    }

    componentDidUpdate() {
        this.updatePlayerState();
    }

    componentWillUnmount() {
        this.player.stopVideo();
        this.player.destroy();
    }

    updatePlayerState() {
        const { play, content } = this.props;

        if (content.mute) {
            this.player.mute();
        } else {
            this.player.unMute();
        }

        if (play) {
            this.player.seekTo(0, true);
            this.player.playVideo();
        } else {
            this.player.stopVideo();
        }
    }

    render() {
        return <div className="full-size" id={this.id}></div>;
    }
}

YoutubeVideo.propTypes = {
    play: PropTypes.bool.isRequired,
    content: PropTypes.object.isRequired,
};
