import { createAction } from '@reduxjs/toolkit';

export const storeResource = createAction('resource/storeResource', (resource, cacheKey) => {
    return {
        payload: {
            resource,
            cacheKey,
        },
    };
});

export const fetchImage = createAction('resource/fetchImage', (imageUrl) => {
    return {
        payload: {
            loader: {
                imageUrl,
            },
            cacheKey: imageUrl,
        },
    };
});
