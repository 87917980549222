import {
    call,
    delay,
    put,
    race,
    select,
    take,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { max } from 'lodash-es';

import { API } from '../../common/API';
import { SWITCH_ANIMATION_DURATION } from '../../components/animation/AnimationDefnitions';

import {
    dequeueNewPreloadSlide,
    lockInteractions,
    scheduleSlideSwap,
    cancelScheduledSlideSwap,
    swapSlides,
    unlockInteractions,
    fetchNextSlide,
    enqueueSlide,
    storeSlide,
    setupInitialSlides,
    dequeueNewDisplaySlide,
    fetchSlideLayout,
    updateDisplaySlide,
    clearQueue,
} from '../actions';

import { selectDisplaySlide } from '../selectors';
import { retryRequest } from './effects';

function mapDirectionToLoader(direction, state) {
    if (direction === 'forward') {
        return () => API.fetchNextSlide(selectDisplaySlide(state)?.slideID);
    }

    if (direction === 'backward') {
        return () => API.fetchPreviousSlide();
    }

    if (typeof direction === 'number') {
        return () => API.fetchSlideById(direction);
    }

    return () => API.fetchNextSlide(selectDisplaySlide(state)?.slideID);
}

function* fetchNextSlideSaga(action) {
    const { direction } = action.payload;

    const state = yield select();
    const nextSlide = yield retryRequest(() => mapDirectionToLoader(direction, state)());

    yield put(storeSlide(nextSlide));
    yield put(fetchSlideLayout(nextSlide.slideID));
    yield put(enqueueSlide(nextSlide.slideID));
}

function* setupInitialSlidesSaga() {
    yield put(fetchNextSlide('forward'));
    yield take(enqueueSlide.type);
    yield put(dequeueNewDisplaySlide());

    yield put(fetchNextSlide('forward'));
    yield take(enqueueSlide.type);
    yield put(dequeueNewPreloadSlide());

    yield put(scheduleSlideSwap());
}

function* updateDisplaySlideSaga(action) {
    const { direction } = action.payload;

    yield put(lockInteractions());

    if (direction === 'forward') {
        yield put(swapSlides());
        yield delay(SWITCH_ANIMATION_DURATION * 1.2);
        yield put(fetchNextSlide('forward'));
        yield take(enqueueSlide.type);
        yield put(dequeueNewPreloadSlide());
    }

    if (direction === 'backward') {
        yield put(fetchNextSlide('backward'));
        yield take(enqueueSlide.type);
        yield put(clearQueue());
        yield put(fetchNextSlide('backward'));
        yield take(enqueueSlide.type);
        yield put(dequeueNewPreloadSlide());
        yield delay(SWITCH_ANIMATION_DURATION * 0.5);
        yield put(swapSlides());
        yield delay(SWITCH_ANIMATION_DURATION * 1.2);
        yield put(fetchNextSlide('forward'));
        yield take(enqueueSlide.type);
        yield put(dequeueNewPreloadSlide());
    }

    if (typeof direction === 'number') {
        yield put(clearQueue());
        yield put(fetchNextSlide(direction));
        yield take(enqueueSlide.type);
        yield put(dequeueNewPreloadSlide());
        yield delay(SWITCH_ANIMATION_DURATION * 0.5);
        yield put(swapSlides());
        yield delay(SWITCH_ANIMATION_DURATION * 1.2);
        yield put(fetchNextSlide('forward'));
        yield take(enqueueSlide.type);
        yield put(dequeueNewPreloadSlide());
    }

    yield put(unlockInteractions());
    yield put(scheduleSlideSwap());
}

function* scheduleNextSlideSaga(action) {
    yield race({
        task: call(function* () {
            const state = yield select();

            const duration = max([
                action.payload.durationOverride ?? selectDisplaySlide(state)?.duration * 1000,
                10_000,
            ]);

            yield delay(duration);
            yield put(updateDisplaySlide('forward'));
        }),
        cancel: take(cancelScheduledSlideSwap.type),
    });
}

function* watchSlideActions() {
    yield takeEvery(fetchNextSlide.type, fetchNextSlideSaga);
    yield takeLatest(scheduleSlideSwap.type, scheduleNextSlideSaga);
    yield takeLatest(updateDisplaySlide.type, updateDisplaySlideSaga);
    yield takeLatest(setupInitialSlides.type, setupInitialSlidesSaga);
}

export const SlideSagas = [watchSlideActions];
