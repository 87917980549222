const selectSlides = (state) => state.slides;

export const selectDisplaySchedule = (state) => {
    return selectSlides(state).displaySchedule;
};

export const selectSlideByID = (slideID, state) => {
    if (slideID == null) {
        return null;
    }

    return selectSlides(state).byId[slideID];
};

export const selectDisplaySlide = (state) => {
    const activeSlides = selectDisplaySchedule(state).filter(
        (slide) => slide.active && slide.slideID != null
    );

    return activeSlides.length === 0 ? null : selectSlideByID(activeSlides[0].slideID, state);
};

export const selectPreloadSlide = (state) => {
    const inactiveSlides = selectDisplaySchedule(state).filter(
        (slide) => !slide.active && slide.slideID != null
    );

    return inactiveSlides.length === 0
        ? null
        : selectSlideByID(inactiveSlides[0].slideID, state);
};
