import React from 'react';
import PropTypes from 'prop-types';

import { VideoJSPlayer } from './VideoJSPlayer';

export const FileVideo = ({ play, content }) => {
    const { sourceLink, audioSourceLink, mute } = content;

    return (
        <VideoJSPlayer
            play={play}
            muted={mute}
            videoSource={sourceLink}
            audioSource={audioSourceLink}
        />
    );
};

FileVideo.propTypes = {
    play: PropTypes.bool.isRequired,
    content: PropTypes.shape({
        sourceLink: PropTypes.string,
        audioSourceLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        mute: PropTypes.bool,
    }).isRequired,
};
