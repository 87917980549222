import { createAction } from '@reduxjs/toolkit';

export const fetchDisplayMeta = createAction('display/fetchDisplayMeta');

export const storeDisplayMeta = createAction('display/storeDisplayMeta', (meta) => ({
    payload: {
        meta,
    },
}));

export const lockInteractions = createAction('display/lockInteractions');
export const unlockInteractions = createAction('display/unlockInteractions');
